import { useRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom"
import { getBrands } from "../../api/axios";
import Header from "../../components/Header";

const JourneyForm = () => {

  const { idCampaign } = useParams();
  
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const nombreRef = useRef();
  const idMarcaRef = useRef();
  const anoRef = useRef();
  
  const [idMarca, setIdMarca] = useState('');
  const [idMarcas, setIdMarcas] = useState('');
  
  const [nombre, setNombre] = useState('');
  const [ano, setAno] = useState('');



  useEffect(() => {

    const fetchData = async () => {
      
      setIsLoading(true);
      
      try {
        
        const data = await getBrands();
        setIdMarcas(data.results);
        setError(null);

      } catch (error) {

        setError(error);
        setIdMarcas(null);

      }

      setIsLoading(false);

    };

    fetchData();

  }, []);



  const handleSubmit = (event) => {

    event.preventDefault();

    // setEmailState(event.target.emailState.value);
    // setEmail(event.target.email.value);
    
  }


  return (

    <>

    <Header />

    <div className="content">

      <h2 className="page_title">Alta de lista de destinatario</h2>


      <div className="form_div">

        <form className="form" action="" onSubmit={handleSubmit}>


          <div className="form_row two">

            <div className="field">
                <label htmlFor="idMarca">Marca</label>
                <select 
                  ref={idMarcaRef}
                  id="idMarca" 
                  onChange={(e) => setIdMarca(e.target.value)}
                >
                  <option value=""></option>
                  
                {!isLoading && idMarcas?.map((option, index) => (
                  <option key={index} value={option.id}>
                    {option.nombre}
                  </option>
                ))}
                
                </select>
            </div>

            <div className="field">
                <label htmlFor="email">Nombre</label>
                <input 
                  ref={nombreRef}
                  type="text" 
                  id="nombre" 
                  autoComplete="off"  
                  onChange={(e) => setNombre(e.target.value)}
                  value={nombre}
                  required
                />
            </div>

          </div>

          <div className="form_row two">

            <div className="field">
                <label htmlFor="ano">Año</label>
                <input 
                  ref={anoRef}
                  type="number" 
                  id="ano" 
                  autoComplete="off"  
                  onChange={(e) => setAno(e.target.value)}
                  value={ano}
                  required
                />
            </div>


          </div>

          <p className={error ? "errmsg" : "hidden"}>{error}</p>

          <div className="buttons">

              <button className="button">Grabar</button>

          </div>

        </form>

      </div>


    </div>



    </>
    
    )
}

export default JourneyForm