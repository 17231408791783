import { useState, useEffect } from "react";
import { getUsers } from "../../api/axios"
import Header from "../../components/Header"
import UsersTable from "../user/UsersTable"

const Users = () => {

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const [email, setEmail] = useState('');

  // https://www.robinwieruch.de/react-hooks-fetch-data/
  
  const handleSubmit = (event) => {

    event.preventDefault();

    setEmail(event.target.email.value);
    
  }

  return (

    <>

      <Header />
              
        <div className="content">

          <h2 className="page_title">Usuarios</h2>

          <div className="table_div">


            <div className="filters_div">

              <form action="" onSubmit={handleSubmit}>

                <ul>

                  
                  <li>
                    <label htmlFor="email">
                      <span>Email</span>
                      <input 
                        name="email" 
                        type="text"                         
                      />
                    </label> 
                  </li>
                  <li>
                    <button className="button-outline" type="submit">Buscar</button>
                  </li>
                </ul>

              </form>

            </div>

            <UsersTable params={ { email } } />

          </div>

        </div>

    </>
    
    )
}

export default Users