import { useRef, useState, useEffect } from "react";
import Header from "../../components/Header"
import DashboardFilters from "./DashboardFilters"
import DashboardPetsTable from "./DashboardPetsTable"


const DashboardPets = () => {

    const [idBrand, setIdBrand] = useState(0);
    const [idContactType, setIdContactType] = useState(0);
    const [idConsent, setIdConsent] = useState(0);
    const [dateFrom, setDateFrom] = useState('');
    const [dateUntil, setDateUntil] = useState('');
    

    return (
        <>
            <Header />
            
            <div className="content">

                <div className="table_div">

                <DashboardFilters setIdBrand={setIdBrand} setIdContactType={setIdContactType} setDateFrom={setDateFrom} setDateUntil={setDateUntil} setIdConsent={setIdConsent}  />


                </div>

                <DashboardPetsTable params={ { idBrand, idContactType, dateFrom, dateUntil, idConsent } } />

            </div>

        </>
    )
}

export default DashboardPets