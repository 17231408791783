import { getContacts, getEmailContacts } from "../../api/dashboard";
import { useState, useEffect } from "react";
import { perc, nf } from "../../utilities/Utils"
import useAuth from "../../hooks/useAuth";

const DashboardTable = (props) => {

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const { auth } = useAuth();

    // CONTACTOS

    // TOTAL DE CONTACTOS
    const [contacts,    setContacts]    = useState(0);

    // POR TIPO DE CONTACTO
    const [pet_owners,  setPetOwners]   = useState(0);
    const [vets,        setVets]        = useState(0);
    const [breeders,    setBreeders]    = useState(0);
    const [students,    setStudents]    = useState(0);
    const [customers,   setCustomers]   = useState(0);
    const [pet_shops,   setPetShops]    = useState(0);
    
    // POR MARCA
    const [royal_canin, setRoyalCanin]  = useState(0);
    const [eukanuba,    setEukanuba]    = useState(0);
    const [mi_vet_shop, setMiVetShop]   = useState(0);


    // ENVIOS

    // TOTAL DE ENVIOS
    const [email_contacts,      setEmailContacts]       = useState(0);

    // POR ACCIONES CONTACTO
    const [ec_unique_contacts,  setECUniqueContacts]    = useState(0);
    const [ec_unique_clicks,    setECUniqueClicks]      = useState(0);
    const [ec_unsubscribed,     setECUnsubscribed]      = useState(0);
    const [ec_opened,           setECOpened]            = useState(0);
    const [ec_bounced,          setECBounced]           = useState(0);
    
    // RATES
    const [ec_bounce_rate,      setECBounceRate]        = useState(0);
    const [ec_open_rate,        setECOpenRate]          = useState(0);
    const [ec_ctr,              setECCTR]               = useState(0);
    const [ec_ctor,             setECCTOR]              = useState(0);
    
    // POR MARCA CONTACTOS
    const [ec_royal_canin,      setECRoyalCanin]  = useState(0);
    const [ec_eukanuba,         setECEukanuba]    = useState(0);
    const [ec_mi_vet_shop,      setECMiVetShop]   = useState(0);

    // POR TIPO DE CONTACTO
    const [ec_pet_owners,       setECPetOwners]   = useState(0);
    const [ec_vets,             setECVets]        = useState(0);
    const [ec_breeders,         setECBreeders]    = useState(0);
    const [ec_students,         setECStudents]    = useState(0);
    const [ec_customers,        setECCustomers]   = useState(0);
    const [ec_pet_shops,        setECPetShops]    = useState(0);

    useEffect(() => {

        const fetchData = async () => {
          
          setIsLoading(true);
          
          try {
            
            let data;

            //////////////////////////////
            // CONTACTOS
            //////////////////////////////

            // TOTAL DE CONTACTOS
            data = await getContacts({ ...props.params });
            setContacts(data.count);

            // POR TIPO DE CONTACTO
            data = await getContacts({ ...props.params, 'idContactType': '1' });
            setPetOwners(data.count);

            data = await getContacts({ ...props.params, 'idContactType': '2' });
            setBreeders(data.count);

            data = await getContacts({ ...props.params, 'idContactType': '3' });
            setStudents(data.count);

            data = await getContacts({ ...props.params, 'idContactType': '4' });
            setVets(data.count);

            data = await getContacts({ ...props.params, 'idContactType': '5' });
            setPetShops(data.count);
    
            data = await getContacts({ ...props.params, 'idContactType': '6' });
            setCustomers(data.count);

            // POR MARCA
            data = await getContacts({ ...props.params, 'idBrand': '1' });
            setRoyalCanin(data.count);
    
            data = await getContacts({ ...props.params, 'idBrand': '2' });
            setEukanuba(data.count);
    
            data = await getContacts({ ...props.params, 'idBrand': '3' });
            setMiVetShop(data.count);

            //////////////////////////////
            // ENVIOS
            //////////////////////////////

            data = await getEmailContacts({ ...props.params });
            setEmailContacts(data.count);

            data = await getEmailContacts({ ...props.params, 'filter': 'ec_unique_contacts' });
            setECUniqueContacts(data.count);

            data = await getEmailContacts({ ...props.params, 'filter': 'ec_unique_clicks' });
            setECUniqueClicks(data.count);

            data = await getEmailContacts({ ...props.params, 'status': 'unsubscribed' });
            setECUnsubscribed(data.count);

            data = await getEmailContacts({ ...props.params, 'status': 'opened' });
            setECOpened(data.count);

            data = await getEmailContacts({ ...props.params, 'status': 'bounced' });
            setECBounced(data.count);

            // CALCULO RATES
            setECBounceRate(perc(email_contacts,ec_bounced));
            setECOpenRate(perc(email_contacts,ec_opened));
            setECCTR(perc(email_contacts,ec_unique_clicks));
            setECCTOR(perc(ec_opened,ec_unique_clicks));

            // POR MARCA
            data = await getEmailContacts({ ...props.params, 'idBrand': 1 });
            setECRoyalCanin(data.count);
    
            data = await getEmailContacts({ ...props.params, 'idBrand': 2 });
            setECEukanuba(data.count);
    
            data = await getEmailContacts({ ...props.params, 'idBrand': 3 });
            setECMiVetShop(data.count);

            // POR TIPO DE CONTACTO
            data = await getEmailContacts({ ...props.params, 'idContactType': 1 });
            setECPetOwners(data.count);

            data = await getEmailContacts({ ...props.params, 'idContactType': 2 });
            setECBreeders(data.count);

            data = await getEmailContacts({ ...props.params, 'idContactType': 3 });
            setECStudents(data.count);

            data = await getEmailContacts({ ...props.params, 'idContactType': 4 });
            setECVets(data.count);

            data = await getEmailContacts({ ...props.params, 'idContactType': 5 });
            setECPetShops(data.count);

            data = await getEmailContacts({ ...props.params, 'idContactType': 6 });
            setECCustomers(data.count);
    
            setError(null);
    
          } catch (error) {
    
            setError(error);
    
          }
    
          setIsLoading(false);
    
        };
    
        fetchData();
        
    }, [props]);


    return (
        <>

                <div className="flex space-around">

                    <div>

                        <h2 className="page_title">Contactos</h2>

                        <table className="table pd10">
                            
                            <thead>
                                <tr>
                                    <td colSpan="2" className="r bold">Total contactos</td>
                                    <td colSpan="2">{ nf(contacts) }</td>
                                </tr>
                            </thead>

                            <tbody>
                            <tr>
                                <td className="bold">Dueños de mascota</td>
                                <td className="r">{ nf(pet_owners) }</td>
                                <td className="bold">Criadores</td>
                                <td className="r">{ nf(breeders) }</td>
                            </tr>
                            <tr>
                                <td className="bold">Vets & Pet Shops directos</td>
                                <td className="r">{ nf(vets) }</td>
                                <td className="bold">Estudiantes</td>
                                <td className="r">{ nf(students) }</td>
                            </tr>
                            <tr>
                                <td className="bold">Vets & Pet Shops distribuidores</td>
                                <td className="r">{ nf(customers) }</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                            </tr>
                            </tbody>
                        </table>

                        <table className="mt--30 table pd10">
                            <tbody>
                                <tr>
                                    <td className="bold">Royal Canin</td>
                                    <td className="r">{ nf(royal_canin) }</td>
                                </tr>
                                <tr>
                                    <td className="bold">Eukanuba</td>
                                    <td className="r">{ nf(eukanuba) }</td>
                                </tr>
                            </tbody>
                        </table>


                    </div>

                    <div>
                    
                        <h2 className="page_title">Envíos</h2>


                        <table className="table pd10">
                            
                            <thead>
                                <tr>
                                    <td colSpan="3" className="r bold">Total emails enviados</td>
                                    <td colSpan="3">{ nf(email_contacts) }</td>
                                </tr>
                            </thead>

                            <tbody>
                            <tr>
                                <td className="bold">Usuarios únicos</td>
                                <td className="r">{ nf(ec_unique_contacts) }</td>
                                <td className="bold">Clicks únicos</td>
                                <td className="r">{ nf(ec_unique_clicks) }</td>
                                <td className="bold">Desuscripciones</td>
                                <td className="r">{ nf(ec_unsubscribed) }</td>
                            </tr>
                            <tr>
                                <td className="bold">Aperturas únicas</td>
                                <td className="r">{ nf(ec_opened) }</td>
                                <td className="bold">Rebotes</td>
                                <td className="r">{ nf(ec_bounced) }</td>
                                <td className="bold">Bounce Rate</td>
                                <td className="r">{ ec_bounce_rate }</td>
                            </tr>
                            <tr>
                                <td className="bold">Open Rate</td>
                                <td className="r">{ ec_open_rate }</td>
                                <td className="bold">Click-Through rate</td>
                                <td className="r">{ ec_ctr }</td>
                                <td className="bold">Clic-to-Open rate</td>
                                <td className="r">{ ec_ctor }</td>
                            </tr>
                            </tbody>
                        </table>


                        <table className="mt--30 table pd10">
                            <tbody>
                                <tr>
                                    <td className="bold">Royal Canin</td>
                                    <td className="r">{ ec_royal_canin }</td>
                                </tr>
                                <tr>
                                    <td className="bold">Eukanuba</td>
                                    <td className="r">{ ec_eukanuba }</td>
                                </tr>
                            </tbody>
                        </table>

                        <table className="mt--30 table pd10">
                            <tbody>
                            <tr>
                                <td className="bold">Dueños de mascota</td>
                                <td className="r">{ nf(ec_pet_owners) }</td>
                                <td className="bold">Criadores</td>
                                <td className="r">{ nf(ec_breeders) }</td>
                            </tr>
                            <tr>
                                <td className="bold">Vets & Pet Shops directos</td>
                                <td className="r">{ nf(ec_vets) }</td>
                                <td className="bold">Estudiantes</td>
                                <td className="r">{ nf(ec_students) }</td>
                            </tr>
                            <tr>
                                <td className="bold">Vets & Pet Shops distribuidores</td>
                                <td className="r">{ nf(ec_customers) }</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                            </tr>
                            </tbody>
                        </table>


                    </div>


                </div>

        </>
    )
}

export default DashboardTable