import { day, perc } from "../../utilities/Utils"
import { getContacts } from "../../api/axios"
import { useState, useEffect } from "react";
import useAuth from "../../hooks/useAuth";

const ContactsTable = (props) => {

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [contacts, setContacts] = useState([]);

// https://www.robinwieruch.de/react-hooks-fetch-data/

const { auth } = useAuth(); 

useEffect(() => {

  const fetchData = async () => {
    
    setIsLoading(true);
    
    try {
      
      const data = await getContacts({props, auth});
      setContacts(data.results);
      setError(null);

    } catch (error) {

      setError(error);
      setContacts(null);

    }

    setIsLoading(false);

  };

  fetchData();
    
}, [props, auth]);

  


  return (

    <>
          
      <table className="table">
                      
        <thead>
            <tr>
                <td className="c">#</td> 
                <td>Email</td> 
                <td className="c">Fecha Alta</td> 
                <td className="c">Fecha Baja</td> 
                <td className="c">Envios</td> 
                <td className="c">Abiertos</td> 
                <td className="c">Clicks</td> 
            </tr>
        </thead>
        <tbody>
            {isLoading ? (
              
              <tr><td colSpan="6" className="c loading">Cargando...</td></tr>

            ) : !contacts?.length ? (

              <tr><td colSpan="6" className="c loading">No se encontraron resultados</td></tr>

            ) : contacts?.map( item => (
              <tr key={item.id} className={item.active == 0 ? 'inactive' : '' }>
                  
                  <td className="c light">{item.id} </td>
                  <td>{item.email}</td>
                  <td className="c">{day(item.fechaAlta)}</td>
                  <td className="c">{day(item.fechaBaja)}</td>
                  <td className="c"> <span className="valor">{item.sent}</span></td>
                  <td className="c">
                    <span className="valor">{item.opened}</span><br />
                    <span className="light">{perc(item.sent,item.opened)}</span>
                  </td>
                  <td className="c">
                    <span className="valor">{item.clicks}</span><br />
                    <span className="light">{perc(item.sent,item.clicks)}</span>
                  </td>
                  
              </tr>
            ))}
            
        </tbody>

      </table>
    </>
    
    )
}

export default ContactsTable