import { useRef, useState, useEffect } from "react";
import { getBrands, getCampaigns, getJourneys } from "../../api/axios"
import Header from "../../components/Header"
import EmailsTable from "./EmailsTable"
import useAuth from "../../hooks/useAuth";

const Emails = () => {

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const idBrandRef = useRef();
  const [idBrand, setIdBrand] = useState(0);
  const [idBrands, setIdBrands] = useState([]);

  const idCampaignRef = useRef();
  const [idCampaign, setIdCampaign] = useState(0);
  const [idCampaigns, setIdCampaigns] = useState([]);

  const idJourneyRef = useRef();
  const [idJourney, setIdJourney] = useState(0);
  const [idJourneys, setIdJourneys] = useState([]);

  const [subject, setSubject] = useState('');

  const { auth } = useAuth();

  useEffect(() => {

    const fetchData = async () => {
      
      setIsLoading(true);
      
      try {
        
        let data = await getBrands({ auth });
        setIdBrands(data.results);

        data = await getCampaigns({ auth });
        setIdCampaigns(data.results);

        data = await getJourneys({ auth });
        setIdJourneys(data.results);

        setError(null);

      } catch (error) {

        setError(error);
        setIdBrands(null);
        setIdCampaigns(null);
        setIdJourneys(null);

      }

      setIsLoading(false);

    };

    fetchData();
    
  }, [auth]);


  
  const handleSubmit = (event) => {

    event.preventDefault();

    setIdBrand(event.target.idBrand.value);
    setIdCampaign(event.target.idCampaign.value);
    setIdJourney(event.target.idJourney.value);
    setSubject(event.target.subject.value);
    
  }

  return (

    <>

      <Header />
              
        <div className="content">

        <h2 className="page_title">Envíos</h2>

          <div className="table_div">

            <div className="filters_div">

              <form action="" onSubmit={handleSubmit}>

                <ul>

                  <li>
                    <label htmlFor="idBrand">
                      <span>Marca</span>
                      <select 
                        ref={idBrandRef}
                        id="idBrand" 
                        name="idBrand" 
                      >
                        <option value="0"></option>
                        
                      {!isLoading && idBrands && idBrands?.map((option, index) => (
                        <option key={index} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                      
                      </select>
                    </label> 
                  </li>
                  <li>
                    <label htmlFor="idCampaign">
                      <span>Campaña</span>
                      <select 
                        ref={idCampaignRef}
                        id="idCampaign" 
                        name="idCampaign" 
                      >
                        <option value="0"></option>
                        
                      {!isLoading && idCampaigns && idCampaigns?.map((option, index) => (
                        <option key={index} value={option.id}>
                          {option.year} | {option.name}
                        </option>
                      ))}
                      
                      </select>
                    </label> 
                  </li>

                  <li>
                    <label htmlFor="idJourney">
                      <span>Journey</span>
                      <select 
                        ref={idJourneyRef}
                        id="idJourney" 
                        name="idJourney" 
                      >
                        <option value="0"></option>
                        
                      {!isLoading && idJourneys && idJourneys?.map((option, index) => (
                        <option key={index} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                      
                      </select>
                    </label> 
                  </li>
                  
                  <li>
                    <label htmlFor="subject">
                      <span>Asunto</span>
                      <input 
                        name="subject" 
                        type="text"                         
                      />
                    </label> 
                  </li>
                  <li>
                    <button className="button-outline" type="submit">Buscar</button>
                  </li>
                  {/* <li>
                    <button className="button-outline" type="submit">Descargar a excel</button>
                  </li> */}
                </ul>

              </form>

            </div>

            <EmailsTable params={ { idBrand, idCampaign, idJourney, subject } } />

          </div>

        </div>

    </>
    
    )
}

export default Emails