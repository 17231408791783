import { useRef, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom"
import { getBrands, getCampaigns, getLists, getJourneys, getEmail, insertEmail, sendTestEmail } from "../../api/axios";
import Header from "../../components/Header";
import useAuth from "../../hooks/useAuth";

const EmailForm = () => {

  const navigate = useNavigate();

  const {prev_idEmail} = useParams();

  const [isLoading, setIsLoading] = useState(true);

  const [idEmail, setIdEmail] = useState(prev_idEmail);

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  // CAMPOS
  const idBrandRef = useRef();
  const [idBrand, setIdBrand] = useState(0);
  const [idBrands, setIdBrands] = useState([]);

  const idCampaignRef = useRef();
  const [idCampaign, setIdCampaign] = useState(0);
  const [idCampaigns, setIdCampaigns] = useState([]);
  
  const nameRef = useRef();
  const [name, setName] = useState('');

  const subjectRef = useRef();
  const [subject, setSubject] = useState('');

  const senderNameRef = useRef();
  const [senderName, setSenderName] = useState('');
  
  const senderEmailRef = useRef();
  const [senderEmail, setSenderEmail] = useState('');
  
  const replyToRef = useRef();
  const [replyTo, setReplyTo] = useState('');
  
  const sendDateFromRef = useRef();
  const [sendDateFrom, setSendDateFrom] = useState('');
    
  const sendDateUntilRef = useRef();
  const [sendDateUntil, setSendDateUntil] = useState('');
  
  const testEmailsRef = useRef();
  const [testEmails, setTestEmails] = useState('');
  
  const idContactListRef = useRef();
  const [idContactList, setIdContactList] = useState(0);
  const [idContactLists, setIdContactLists] = useState([]);
  
  const idJourneyRef = useRef();
  const [idJourney, setIdJourney] = useState(0);
  const [idJourneys, setIdJourneys] = useState([]);

  const htmlRef = useRef();
  const [html, setHtml] = useState('');
  
  const resendDaysRef = useRef();
  const [resendDays, setResendDays] = useState(0);
  
  const resendSubjectRef = useRef();
  const [resendSubject, setResendSubject] = useState('');

  const activeRef = useRef();
  const [active, setActive] = useState(0);

  const { auth } = useAuth();


  useEffect(() => {

    const fetchData = async () => {
      
      setIsLoading(true);
      
      try {
        
        let data = await getCampaigns({ auth });
        setIdCampaigns(data.results);

        data = await getLists({ auth });
        setIdContactLists(data.results);

        data = await getJourneys({ auth });
        setIdJourneys(data.results);

        data = await getBrands({ auth });
        setIdBrands(data.results);

        if(idEmail) {

          data = await getEmail(idEmail, auth);
          
          if(data?.results) {

            setActive(data.results.active)
            setIdBrand(data.results.brand_id ?? 0);
            setIdCampaign(data.results.campaign_id ?? 0);
            setIdContactList(data.results.contact_list_id ?? 0);
            setIdJourney(data.results.journey_id ?? 0);
            setName(data.results.name ?? '');
            setSubject(data.results.subject ?? '');
            setSenderName(data.results.sender_name ?? '');
            setSenderEmail(data.results.sender_email ?? '');
            setReplyTo(data.results.reply_to ?? '');
            setSendDateFrom(data.results.date_sent_from ?? '');
            setSendDateUntil(data.results.date_sent_until ?? '');
            setTestEmails(data.results.test_emails ?? '');
            setHtml(data.results.html ?? '');
            setResendDays(data.results.resend_days ?? '');
            setResendSubject(data.results.resend_subject ?? '');

          }

        }

        setError(null);

      } catch (error) {

        setError(error);
        setIdCampaigns(null);
        setIdContactLists(null);
        setIdJourneys(null);
        setIdBrands(null);

      }

      setIsLoading(false);

    };

    fetchData();

  }, [auth, idEmail]);



  const handleTestEmail = async (e) => {

    e.preventDefault();

    setError(null);
    setSuccess(null);

    try {
      
      const data = await sendTestEmail(
        auth,
        idEmail, 
        testEmails,
      );

      if(data.status === 'ok') {
        
        setSuccess('Se envió la prueba con éxito.');
        
      }else{
        
        setError(data.error);


      } 


    } catch (error) {

      setError('Hubo un error al generar el envío.');
      
    }

  }



  const handleEmailSave = async (e) => {

    e.preventDefault();
    
    try {
      
      const data = await insertEmail(
        auth,
        idEmail, 
        active,
        idBrand, 
        idCampaign,
        name, 
        subject,
        senderName,
        senderEmail,
        replyTo,
        sendDateFrom,
        sendDateUntil,
        testEmails,
        idContactList,
        idJourney,
        html,
        resendDays,
        resendSubject
      );

      if(data.status === 'ok') {

        if(data.new_email_id) {

          setIdEmail(data.new_email_id);
          navigate(`/emails/edit/${data.new_email_id}`);
          setSuccess('Se generó el envío con éxito.');
          
        }else{
          
          setSuccess('Se actualizó el envío con éxito.');

        }

        

      }
      

    } catch (error) {

      setError('Hubo un error al generar el envío.');
      
    }

  }


  return (

    <>

    <Header />

    <div className="content">


      <h2 className="page_title">Alta de envío</h2>

      <div className="form_div">

        <form className="form" action="POST">

        <input 
                  type="hidden" 
                  id="idEmail" 
                  name="idEmail" 
                  value={idEmail}
                  readOnly
                />

          
          <div className="form_row">

            <div className="field right">
              
              { Number(active) === 1
                ? <label className="pr--10 strong">Activo</label>
                : <label className="pr--10 strong">Inactivo</label>
              }

              <input type="checkbox" 
                checked={active === "1"}
                value="1"
                ref={activeRef}
                id="active"
                onChange={(e) => setActive(e.target.checked ? "1" : "0")} />

            </div>

          </div>

          <div className="form_row two">


            <div className="field">
                <label htmlFor="idBrand">Marca</label>
                <select value={idBrand}
                  ref={idBrandRef}
                  id="idBrand" 
                  onChange={(e) => setIdBrand(e.target.value)}
                >
                  <option value="0"></option>
                  
                {!isLoading && idBrands && idBrands?.map((option, index) => (
                  <option key={index} value={option.id}>
                    {option.name}
                  </option>
                ))}
                
                </select>
            </div>

            <div className="field">
                <label htmlFor="idCampaign">Campaña</label>
                <select value={idCampaign}
                  ref={idCampaignRef}
                  id="idCampaign" 
                  onChange={(e) => setIdCampaign(e.target.value)}
                >
                  <option value="0"></option>
                  
                {!isLoading && idCampaigns && idCampaigns?.map((option, index) => (
                  <option key={index} value={option.id}>
                    {option.name}
                  </option>
                ))}
                
                </select>
            </div>

          </div>

          <div className="form_row two">

            <div className="field">
                <label htmlFor="name">Nombre interno</label>
                <input 
                  ref={nameRef}
                  type="text" 
                  id="name" 
                  autoComplete="off"  
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  required
                />
            </div>

            <div className="field">
                <label htmlFor="email">Asunto</label>
                <input 
                  ref={subjectRef}
                  type="text" 
                  id="subject" 
                  autoComplete="off"  
                  onChange={(e) => setSubject(e.target.value)}
                  value={subject}
                  required
                />
            </div>

          </div>



          <div className="form_row two">

            <div className="field">
              <label htmlFor="senderName">Remitente name</label>
              <input 
                ref={senderNameRef}
                type="text" 
                id="senderName" 
                autoComplete="off"  
                onChange={(e) => setSenderName(e.target.value)}
                value={senderName}
                required
              />
            </div>

            <div className="field">
              <label htmlFor="senderEmail">Remitente email</label>
              <input 
                ref={senderEmailRef}
                type="email" 
                id="senderEmail" 
                autoComplete="off"  
                onChange={(e) => setSenderEmail(e.target.value)}
                value={senderEmail}
                required
              />
            </div>

          </div>

          <div className="form_row two">

            <div className="field">
              <label htmlFor="replyTo">ReplyTo email</label>
              <input 
                ref={replyToRef}
                type="email" 
                id="replyTo" 
                autoComplete="off"  
                onChange={(e) => setReplyTo(e.target.value)}
                value={replyTo}
                required
              />
            </div>

            <div className="field">
                <label htmlFor="testEmails">Emails de prueba</label>
                <input 
                  ref={testEmailsRef}
                  type="text" 
                  id="testEmails" 
                  autoComplete="off"  
                  onChange={(e) => setTestEmails(e.target.value)}
                  value={testEmails}
                  required
                />
            </div>

          </div>

          <div className="form_row two">

            <div className="field">
                <label htmlFor="sendDateFrom">Fecha de envío</label>
                <input 
                  ref={sendDateFromRef}
                  type="text" 
                  id="sendDateFrom" 
                  autoComplete="off"  
                  onChange={(e) => setSendDateFrom(e.target.value)}
                  value={sendDateFrom}
                  placeholder="aaaa-mm-dd hh:mm:ss"
                  required
                />
            </div>


            <div className="field">
                <label htmlFor="sendDateUntil">Fecha de envío final</label>
                <input 
                  ref={sendDateUntilRef}
                  type="text" 
                  id="sendDateUntil" 
                  autoComplete="off"  
                  onChange={(e) => setSendDateUntil(e.target.value)}
                  value={sendDateUntil}
                  placeholder="aaaa-mm-dd hh:mm:ss"
                  required
                />
            </div>
          
          </div>

          <div className="form_row two">

            <div className="field">
                <label htmlFor="idJourney">Journey asociado</label>
                <select value={idJourney}
                  ref={idJourneyRef}
                  id="idJourney" 
                  onChange={(e) => setIdJourney(e.target.value)}
                >
                  <option value="0"></option>
                  
                {!isLoading && idJourneys?.map((option, index) => (
                  <option key={index} value={option.id}>
                    {option.name}
                  </option>
                ))}
                
                </select>
            </div>

            <div className="field">
                <label htmlFor="idContactList">Lista de destinatarios asociada</label>
                <select value={idContactList}
                  ref={idContactListRef}
                  id="idContactList" 
                  onChange={(e) => setIdContactList(e.target.value)}
                >
                  <option value="0"></option>
                  
                {!isLoading && idContactLists?.map((option, index) => (
                  <option key={index} value={option.id}>
                    {option.name}
                  </option>
                ))}
                
                </select>
            </div>

          </div>

          <div className="form_row">
            <div className="field">
              <label htmlFor="html">Código HTML de la pieza</label>
              <textarea 
                ref={htmlRef} 
                id="html"
                onChange={(e) => setHtml(e.target.value)}
                value={html}
                spellCheck="false"
                required
              ></textarea>
            </div>
          </div>

          <div className="form_row two">

            <div className="field checkbox">
                
                <label htmlFor="resendDays">
                    Reenviar a no abiertos en 
                    <select value={resendDays}
                      ref={resendDaysRef}
                      id="resendDays" 
                      onChange={(e) => setResendDays(e.target.value)}
                    >
                      <option value="">No reenviar</option>
                      <option value="1">1 día</option>
                      <option value="2">2 días</option>
                      <option value="3">3 días</option>
                      <option value="4">4 días</option>
                      <option value="5">5 días</option>
                      <option value="6">6 días</option>
                      <option value="7">7 días</option>
                    </select>
                </label>

            </div>

            { resendDays > 0  && (
            <div className="field">
                <label htmlFor="resendSubject">Asunto para el reenvío</label>
                <input 
                  type="text" 
                  ref={resendSubjectRef}
                  id="resendSubject" 
                  autoComplete="off"  
                  onChange={(e) => setResendSubject(e.target.value)}
                  value={resendSubject}
                  required
                />
            </div>
            )}

          </div>


          <p className={error ? "errmsg" : "hidden"}>{error}</p>
          <p className={success ? "successmsg" : "hidden"}>{success}</p>
          
          <div className="buttons">

              <button type="button" className="button" onClick={handleEmailSave}>Grabar envío</button>
              <button type="button" className="button" disabled={ idEmail ? false : true } onClick={handleTestEmail}>Enviar pruebas</button>
              

          </div>


        </form>

      </div>


    </div>

    </>
    
    )
}

export default EmailForm