import { useRef, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom"
import { getProfiles, getUser, insertUser } from "../../api/axios";
import Header from "../../components/Header";
import useAuth from "../../hooks/useAuth";

const UserForm = () => {

  const navigate = useNavigate();

  const { prev_idUser } = useParams();
  
  const [isLoading, setIsLoading] = useState(true);

  const [idUser, setIdUser] = useState(prev_idUser);

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  // CAMPOS
  const idProfileRef = useRef();
  const [idProfile, setIdProfile] = useState(0);
  const [idProfiles, setIdProfiles] = useState([]);

  const nameRef = useRef();
  const [name, setName] = useState('');

  const lastNameRef = useRef();
  const [lastName, setLastName] = useState('');
  
  const emailRef = useRef();
  const [email, setEmail] = useState('');
  
  const companyRef = useRef();
  const [company, setCompany] = useState('');
  
  const cellPhoneRef = useRef();
  const [cellPhone, setCellPhone] = useState('');
  
  const { auth } = useAuth();

  useEffect(() => {

    const fetchData = async () => {
      
      setIsLoading(true);
      
      try {
        
        let data = await getProfiles({ auth });
        setIdProfiles(data.results);

        if(idUser) {

          data = await getUser(idUser, auth);
          
          if(data?.results) {

            setIdProfile(data.results.profile_id ?? 0);

            setName(data.results.name ?? '');
            setLastName(data.results.last_name ?? '');
            setEmail(data.results.email ?? '');
            setCompany(data.results.company ?? '');
            setCellPhone(data.results.cell_phone ?? '');

          }

        }
        
      } catch (error) {

        setError(error);
        setIdProfiles(null);

      }

      setIsLoading(false);

    };

    fetchData();

  }, [idUser, auth]);



  const handleSubmit = async (e) => {

    e.preventDefault();
    
    try {
      
      const data = await insertUser(
        auth, 
        idUser,
        name, 
        lastName, 
        email, 
        company, 
        idProfile, 
        cellPhone
      );

      if(data.status === 'ok') {

        if(data.new_user_id) {

          setIdUser(data.new_user_id);
          navigate(`/users/edit/${data.new_user_id}`);
          setError(null);
          setSuccess('Se generó el usuario con éxito.');
          
        }else{
          
          setError(null);
          setSuccess('Se actualizó el usuario con éxito.');

        }

      }
      else {

        setError(data?.message);
        setSuccess(null);

      }
      

    } catch (error) {

      if (!error?.response) {
        setError('No Server Response');
      } else if (error.response?.status === 400) {
          setError('Missing Username or Password');
      } else if (error.response?.status === 401) {
          setError('Unauthorized');
      } else {
          setError('Login Failed');
      }
      
    }

  }

  return (

    <>

    <Header />

    <div className="content">

      <h2 className="page_title">Alta de usuario</h2>


      <div className="form_div">

        <form className="form" action="" onSubmit={handleSubmit}>

        <input 
                  type="hidden" 
                  id="idUser" 
                  name="idUser" 
                  value={idUser}
                  readOnly
                />


          <div className="form_row two">

           <div className="field">
                <label htmlFor="name">Nombre</label>
                <input 
                  ref={nameRef}
                  type="text" 
                  id="name" 
                  autoComplete="off"  
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  required
                />
            </div>

            <div className="field">
                <label htmlFor="lastName">Apellido</label>
                <input 
                  ref={lastNameRef}
                  type="text" 
                  id="lastName" 
                  autoComplete="off"  
                  onChange={(e) => setLastName(e.target.value)}
                  value={lastName}
                  required
                />
            </div>

          </div>

          <div className="form_row two">

           <div className="field">
                <label htmlFor="email">Email</label>
                <input 
                  ref={emailRef}
                  type="text" 
                  id="email" 
                  autoComplete="off"  
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  required
                />
            </div>

            <div className="field">
                <label htmlFor="company">Empresa</label>
                <input 
                  ref={companyRef}
                  type="text" 
                  id="company" 
                  autoComplete="off"  
                  onChange={(e) => setCompany(e.target.value)}
                  value={company}
                  required
                />
            </div>

          </div>

          <div className="form_row two">

            <div className="field">
                <label htmlFor="idProfile">Perfil</label>
                <select value={idProfile}
                  ref={idProfileRef}
                  id="idProfile" 
                  onChange={(e) => setIdProfile(e.target.value)}
                >
                <option value=""></option>
                {!isLoading && idProfiles && idProfiles?.map((option, index) => (
                  <option key={index} value={option.id}>
                    {option.name}
                  </option>
                ))}
                
                </select>
            </div>

            <div className="field">
                <label htmlFor="cellPhone">Celular</label>
                <input 
                  ref={cellPhoneRef}
                  type="text" 
                  id="cellPhone" 
                  autoComplete="off"  
                  onChange={(e) => setCellPhone(e.target.value)}
                  value={cellPhone}
                />
            </div>


          </div>

          <p className={error ? "errmsg" : "hidden"}>{error}</p>
          <p className={success ? "successmsg" : "hidden"}>{success}</p>

          <div className="buttons">

              <button className="button">Grabar</button>

          </div>

        </form>

      </div>


    </div>



    </>
    
    )
}

export default UserForm