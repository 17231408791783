import { Link } from "react-router-dom"
import { getJourneys } from "../../api/axios"
import { useState, useEffect } from "react";
import useAuth from "../../hooks/useAuth";



const JourneysTable = (props) => {

const [isLoading, setIsLoading] = useState(true);
const [error, setError] = useState(null);
const [journeys, setJourneys] = useState([]);

// https://www.robinwieruch.de/react-hooks-fetch-data/

const { auth } = useAuth();

useEffect(() => {

  const fetchData = async () => {
      
    setIsLoading(true);
    
    try {
      
      const data = await getJourneys({props, auth});
      setJourneys(data.results);
      
      setError(null);

    } catch (error) {

      setError(error);
      setJourneys(null);

    }

    setIsLoading(false);

  };



  fetchData();
    
}, [props, auth]);

  


  return (

    <>
          
      <table className="table">
                      
        <thead>
            <tr>
                <td>#</td>
                <td>Marca</td> 
                <td>Nombre</td> 
                <td>Piezas</td> 
            </tr>
        </thead>
        <tbody>
            {isLoading ? (
              
              <tr><td colSpan="4" className="c loading">Cargando...</td></tr>

            ) : !journeys?.length ? (

              <tr><td colSpan="4" className="c loading">No se encontraron journeys</td></tr>

            ) : journeys.map( item => (

              <tr key={item.id}>
                  
                  <td className="c light">{item.id} </td>
                  
                  <td>
                    <span>{item.brand_name}</span>
                  </td>
                  
                  <td>
                    {item.name}
                  </td>
                  
                  <td className="c">
                    <span>{item.cantidad}</span>
                  </td>
                  
              </tr>
            ))}
            
        </tbody>

      </table>
    </>
    
    )
}

export default JourneysTable