import { getPets, getSources } from "../../api/dashboard";
import { useState, useEffect } from "react";
import { nf, perc } from "../../utilities/Utils"

const DashboardPetsTable = (props) => {

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingSources, setIsLoadingSources] = useState(true);
  const [error, setError] = useState(null);

  const [pets,      setPets]       = useState(0);
  const [dogs,      setDogs]       = useState(0);
  const [cats,      setCats]       = useState(0);
  
  const [cat_kitten_owners, setCatKittenOwners] = useState(0);
  const [cat_adult_owners,  setCatAdultOwners]  = useState(0);
  const [cat_mature_owners, setCatMatureOwners] = useState(0);
  const [cat_senior_owners, setCatSeniorOwners] = useState(0);
  
  const [dog_xsmall_owners, setDogXsmallOwners] = useState(0);
  const [dog_small_owners,  setDogSmallOwners]  = useState(0);
  const [dog_medium_owners, setDogMediumOwners] = useState(0);
  const [dog_large_owners,  setDogLargeOwners]  = useState(0);
  const [dog_giant_owners,  setDogGiantOwners]  = useState(0);
  
  const [dog_puppy_owners,  setDogPuppyOwners] = useState(0);
  const [dog_adult_owners,  setDogAdultOwners]  = useState(0);
  const [dog_mature_owners, setDogMatureOwners] = useState(0);
  const [dog_senior_owners, setDogSeniorOwners] = useState(0);

  const [dogs_by_breed, setDogsByBreed] = useState([]);
  const [cats_by_breed, setCatsByBreed] = useState([]);

  

  // FUENTES
  const [sources, setSources]           = useState([]);
  const [activations, setActivations]   = useState([]);
  const [events, setEvents]             = useState([]);
  


  useEffect(() => {

    const fetchData = async () => {
      
      setIsLoading(true);
      
      try {
        
        let data = 0;

        //////////////////////////////
        // CONTACTOS
        //////////////////////////////

        // PETS
        data = await getPets({ ...props.params });
        setPets(data.count);

        data = await getPets({ ...props.params, 'hasDog': true });
        setDogs(data.count);

        data = await getPets({ ...props.params, 'hasCat': true });
        setCats(data.count);


        // FUENTES
        data = await getSources({ 'idParentSource': 0 });
        setSources(data.results);

        // ACTIVACIONES
        data = await getSources({ 'idParentSource': 11 });
        setActivations(data.results);

        // EVENTOS
        data = await getSources({ 'idParentSource': 6 });
        setEvents(data.results);
        
        

        // GATO - ETAPA DE VIDA 
        data = await getPets({ ...props.params, 'hasCatKitten': true });
        setCatKittenOwners(data.count);

        data = await getPets({ ...props.params, 'hasCatAdult': true });
        setCatAdultOwners(data.count);

        data = await getPets({ ...props.params, 'hasCatMature': true });
        setCatMatureOwners(data.count);

        data = await getPets({ ...props.params, 'hasCatSenior': true });
        setCatSeniorOwners(data.count);


        // PERRO - TAMAÑO
        data = await getPets({ ...props.params, 'hasDogXsmall': true });
        setDogXsmallOwners(data.count);

        data = await getPets({ ...props.params, 'hasDogSmall': true });
        setDogSmallOwners(data.count);

        data = await getPets({ ...props.params, 'hasDogMedium': true });
        setDogMediumOwners(data.count);

        data = await getPets({ ...props.params, 'hasDogLarge': true });
        setDogLargeOwners(data.count);

        data = await getPets({ ...props.params, 'hasDogGiant': true });
        setDogGiantOwners(data.count);


        // PERRO - ETAPA DE VIDA
        data = await getPets({ ...props.params, 'hasDogPuppy': true });
        setDogPuppyOwners(data.count);

        data = await getPets({ ...props.params, 'hasDogAdult': true });
        setDogAdultOwners(data.count);

        data = await getPets({ ...props.params, 'hasDogMature': true });
        setDogMatureOwners(data.count);

        data = await getPets({ ...props.params, 'hasDogSenior': true });
        setDogSeniorOwners(data.count);

        // POR RAZA
        data = await getPets({ ...props.params, 'hasDog': true, 'groupBy': 'breed' });
        setDogsByBreed(data.count);
        
        // POR RAZA
        data = await getPets({ ...props.params, 'hasCat': true, 'groupBy': 'breed' });
        setCatsByBreed(data.count);

        setError(null);

      } catch (error) {

        setError(error);

      }

      setIsLoading(false);

    };

    fetchData();
    
  }, [props]);

  const [sourcesCant, setSourcesCant] = useState({});

  const getPetsBySource = async (idSource) => {
        
    try {
        
        let data = await getPets( { ...props.params, idSource, 'hasDog': true } );
        setSourcesCant(prevContacts => ({ ...prevContacts, [idSource]: { ...prevContacts[idSource], 'perros' : data.count } }));

        data = await getPets( { ...props.params, idSource, 'hasCat': true } );
        setSourcesCant(prevContacts => ({ ...prevContacts, [idSource]: { ...prevContacts[idSource], 'gatos' : data.count } }));

    } catch (error) {

        console.log(error);
        return 'Error';

    }

  };


  useEffect(() => {

    sources.forEach(source => {
        getPetsBySource(source.id);
    });
  
  }, [sources]);

  useEffect(() => {
    
    activations.forEach(source => {
      
      getPetsBySource(source.id);
    });

  }, [activations]);

  useEffect(() => {

      events.forEach(source => {
        getPetsBySource(source.id);
      });

  }, [events]);


    return (
        <>


<div className="flex space-around">

<div>

    <h2 className="page_title">Mascotas</h2>

    <table className="table pd10 mb--50">

        <tbody>
        <tr>
            <td className="bold">Total</td>
            <td className="r">{nf(pets)}</td>
        </tr>
        <tr>
            <td className="bold">Perros</td>
            <td className="r">{nf(dogs)}</td>
        </tr>
        <tr>
            <td className="bold">Gatos</td>
            <td className="r">{nf(cats)}</td>
        </tr>
        </tbody>
    </table>

    
    <h2 className="page_title">Fuentes</h2>

    <table className="table pd10 mb--50">
        
        <thead>
            <tr>
                <td>Fuente</td>
                <td>Perros</td>
                <td>Gatos</td>
            </tr>
        </thead>

        <tbody>
            { sources.map( item => (
            
            <tr key={item.id}>
                <td className="bold">{ item.name }</td>
                <td className="r">{ nf(sourcesCant[item.id]?.perros) }</td>
                <td className="r">{ nf(sourcesCant[item.id]?.gatos) }</td>
            </tr>

            ))}
        </tbody>
    </table>


{ true && 
<div className="mt--30">

    <h2 className="page_title">Activaciones</h2>

    <table className="table pd10">
        
        <tbody>
            { activations.map( item => (
                
            <tr key={item.id}>
                <td className="bold">{ item.name }</td>
                <td className="r">{ nf(sourcesCant[item.id]?.perros) }</td>
                <td className="r">{ nf(sourcesCant[item.id]?.gatos) }</td>
            </tr>

            ))}
        </tbody>

    </table>



</div>
}



{ true && 
<div className="mt--30">

    <h2 className="page_title">Eventos</h2>

    <table className="table pd10">
        
        <tbody>
        { events.map( item => (
            
        <tr key={item.id}>
            <td className="bold">{ item.name }</td>
            <td className="r">{ nf(sourcesCant[item.id]?.perros) }</td>
            <td className="r">{ nf(sourcesCant[item.id]?.gatos) }</td>
        </tr>

        ))}
        </tbody>
    </table>



</div>
}







</div>

<div>

    <h2 className="page_title">Perros</h2>


    <div className="flex cg--20 mb--50">

      <div>

        <table className="table pd10">
            
            <thead>
                <tr>
                    <td>Etapa</td>
                    <td>Perros</td>
                    <td>%</td>
                </tr>
            </thead>

            <tbody>
                <tr>
                    <td className="bold">Cachorro</td>
                    <td className="r">{ nf(dog_puppy_owners) }</td>
                    <td className="r">{ perc(dogs, dog_puppy_owners) }</td>
                </tr>
                <tr>
                    <td className="bold">Adulto</td>
                    <td className="r">{ nf(dog_adult_owners) }</td>
                    <td className="r">{ perc(dogs, dog_adult_owners) }</td>
                </tr>
                <tr>
                    <td className="bold">Maduro</td>
                    <td className="r">{ nf(dog_mature_owners) }</td>
                    <td className="r">{ perc(dogs, dog_mature_owners) }</td>
                </tr>
                <tr>
                    <td className="bold">Senior</td>
                    <td className="r">{ nf(dog_senior_owners) }</td>
                    <td className="r">{ perc(dogs, dog_senior_owners) }</td>
                </tr>
            </tbody>
        </table>

      </div>

      <div>
    
        <table className="table pd10">
            
            <thead>
                <tr>
                    <td>Talla</td>
                    <td>Perros</td>
                    <td>%</td>
                </tr>
            </thead>

            <tbody>
                <tr>
                    <td className="bold">Muy Pequeña</td>
                    <td className="r">{ nf(dog_xsmall_owners) }</td>
                    <td className="r">{ perc(dogs, dog_xsmall_owners) }</td>
                </tr>
                <tr>
                    <td className="bold">Pequeña</td>
                    <td className="r">{ nf(dog_small_owners) }</td>
                    <td className="r">{ perc(dogs, dog_small_owners) }</td>
                </tr>
                <tr>
                    <td className="bold">Mediana</td>
                    <td className="r">{ nf(dog_medium_owners) }</td>
                    <td className="r">{ perc(dogs, dog_medium_owners) }</td>
                </tr>
                <tr>
                    <td className="bold">Grande</td>
                    <td className="r">{ nf(dog_large_owners) }</td>
                    <td className="r">{ perc(dogs, dog_large_owners) }</td>
                </tr>
                <tr>
                    <td className="bold">Gigante</td>
                    <td className="r">{ nf(dog_giant_owners) }</td>
                    <td className="r">{ perc(dogs, dog_giant_owners) }</td>
                </tr>
            </tbody>
        </table>

      </div>

    </div>







        <h2 className="page_title">Razas</h2>

        <table className="table pd10 mb--50">
            
            <tbody>
            { dogs_by_breed.slice(0,20).map( item => (
            
                <tr key={item.id}>
                    <td className="bold">{ item.breed }</td>
                    <td className="r">{ nf(item.cant) }</td>
                    <td className="r">{ perc(dogs, item.cant) }</td>
                </tr>

            ))
            }
            </tbody>

        </table>
{/* 

        <h2 className="page_title">Necesidades Especiales</h2>

        <table className="table pd10 mb--50">
            
          <tbody>
          <tr><td>Sin necesidades especiales</td><td className="r">6858</td><td className="r">86%</td></tr>
          <tr><td>Actividad física alta</td><td className="r">598</td><td className="r">8%</td></tr>
          <tr><td>Adaptación a cambios y situaciones de estrés</td><td className="r">503</td><td className="r">5%</td></tr>
          <tr><td>Apetito caprichoso</td><td className="r">300</td><td className="r">5%</td></tr>
          <tr><td>Bajo peso</td><td className="r">263</td><td className="r">5%</td></tr>
          <tr><td>Castrado</td><td className="r">247</td><td className="r">5%</td></tr>
          <tr><td>Crecimiento</td><td className="r">237</td><td className="r">5%</td></tr>
          </tbody>
        </table> */}


    


</div>

<div>

    <h2 className="page_title">Gatos</h2>

    <table className="table pd10 mb--50">
        
        <thead>
            <tr>
                <td>Etapa</td>
                <td>Gatos</td>
                <td>%</td>
            </tr>
        </thead>

        <tbody>
            <tr>
                <td className="bold">Gatito</td>
                <td className="r">{ nf(cat_kitten_owners) }</td>
                <td className="r">{ perc(cats, cat_kitten_owners) }</td>
                
            </tr>
            <tr>
                <td className="bold">Adulto</td>
                <td className="r">{ nf(cat_adult_owners) }</td>
                <td className="r">{ perc(cats, cat_adult_owners) }</td>
                
            </tr>
            <tr>
                <td className="bold">Maduro</td>
                <td className="r">{ nf(cat_mature_owners) }</td>
                <td className="r">{ perc(cats, cat_mature_owners) }</td>
                
            </tr>
            <tr>
                <td className="bold">Senior</td>
                <td className="r">{ nf(cat_senior_owners) }</td>
                <td className="r">{ perc(cats, cat_senior_owners) }</td>
                
            </tr>
        </tbody>
    </table>

    <h2 className="page_title">Razas</h2>

    <table className="table pd10 mb--50">
        
        <tbody>
        
        { cats_by_breed.slice(0,20).map( item => (
            
            <tr key={item.id}>
                <td className="bold">{ item.breed }</td>
                <td className="r">{ nf(item.cant) }</td>
                <td className="r">{ perc(dogs, item.cant) }</td>
            </tr>

        ))}
        
        </tbody>

    </table>
{/* 


    <h2 className="page_title">Necesidades Especiales</h2>

    <table className="table pd10 mb--50">
        
      <tbody>
      <tr><td>Sin necesidades especiales</td><td className="r">6858</td><td className="r">86%</td></tr>
      <tr><td>Actividad física alta</td><td className="r">598</td><td className="r">8%</td></tr>
      <tr><td>Adaptación a cambios y situaciones de estrés</td><td className="r">503</td><td className="r">5%</td></tr>
      <tr><td>Apetito caprichoso</td><td className="r">300</td><td className="r">5%</td></tr>
      <tr><td>Bajo peso</td><td className="r">263</td><td className="r">5%</td></tr>
      <tr><td>Castrado</td><td className="r">247</td><td className="r">5%</td></tr>
      <tr><td>Crecimiento</td><td className="r">237</td><td className="r">5%</td></tr>
      </tbody>
    </table>
 */}


</div>

</div>


        </>
    )
}

export default DashboardPetsTable