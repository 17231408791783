import { useState, useEffect } from "react";
import { useParams, NavLink } from "react-router-dom"
import { validatePassword } from "../../utilities/Utils"

import { passwordReset } from "../../api/axios"
import Logo from "../../components/Logo";

const PasswordReset = () => {
  
  const { hash } = useParams();
    
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(()=>{

    setError(null);

  },[password,password2]);


  
  const handleSubmit = async (e) => {

    e.preventDefault();

    if(password !== password2) {

      setError('Las contraseñas no coinciden');
      return;

    }

    if(!validatePassword(password)) {

      setError('La contraseña debe contener como mínimo 12 caracteres. Además, debe incluir al menos un número y opcionalmente una mayúscula o un carácter especial.');
      return;

    }
    
    try {
      
      const data = await passwordReset(hash, password);

      console.log(data);

      if(data?.status === 'ok') {

        setError(null);
        setSuccess('La nueva contraseña fue generada con exito');
        
      }else{
        
        setError('Hubo un error al guardar la nueva contraseña');
        setSuccess(null);

      }

      


    } catch (error) {

      if (!error?.response) {
        setError('No Server Response');
      } else if (error.response?.status === 400) {
        setError('Missing Username or Password');
      } else if (error.response?.status === 401) {
        setError('Unauthorized');
      } else {
        setError('Login Failed');
      }
      
    }

  }

  return (
    
        <div className="content white">

            <Logo />

            <form className="form login" onSubmit={handleSubmit}>

                <p className="mb--30 strong">Genere una nueva contraseña</p>
              
                <div className="field">
                    <label htmlFor="password">Contraseña</label>
                    <input 
                      type="password" 
                      id="password"
                      autoComplete="off"  
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                </div>

                <div className="field">
                    <label htmlFor="password2">Repita la contraseña</label>
                    <input 
                      type="password" 
                      id="password2"
                      onChange={(e) => setPassword2(e.target.value)}
                      required
                    />
                </div>

                <p className="center mb--30"><NavLink to="/login" className="underline">Acceso con usuario y contraseña</NavLink></p>

                <p className={error ? "errmsg" : "hidden"}>{error}</p>
                <p className={success ? "successmsg" : "hidden"}>{success}</p>

                <div className="buttons">

                    <button className="button">Guardar nueva contraseña</button>

                </div>

            </form>

        </div>
   
    )
}

export default PasswordReset