import { useRef, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom"
import { getBrands, getContactTypes, getContactsList, insertContactsList } from "../../api/axios";
import Header from "../../components/Header";
import useAuth from "../../hooks/useAuth";

const ContactsListForm = () => {

  const navigate = useNavigate();

  const { prev_idContactsList } = useParams();
  
  const [isLoading, setIsLoading] = useState(true);

  const [idContactsList, setidContactsList] = useState(prev_idContactsList);

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  // CAMPOS
  const idBrandRef = useRef();
  const [idBrand, setIdBrand] = useState(0);
  const [idBrands, setIdBrands] = useState([]);

  const idContactTypeRef = useRef();
  const [idContactType, setIdContactType] = useState(0);
  const [idContactTypes, setIdContactTypes] = useState([]);

  const nameRef = useRef();
  const [name, setName] = useState('');

  const { auth } = useAuth();



  useEffect(() => {

    const fetchData = async () => {
      
      setIsLoading(true);
      
      try {
        
        let data = await getBrands({ auth });
        setIdBrands(data.results);

        data = await getContactTypes({ auth });
        setIdContactTypes(data.results);

        if(idContactsList) {

          data = await getContactsList(idContactsList, auth);
          
          if(data?.results) {

            setIdBrand(data.results.brand_id ?? 0);
            setIdContactType(data.results.contact_type_id ?? 0);

            setName(data.results.name ?? '');

          }

        }

      } catch (error) {

        setError(error);
        setIdBrands(null);

      }

      setIsLoading(false);

    };

    fetchData();

  }, []);



  const handleSubmit = async (e) => {

    e.preventDefault();
    
    try {
      
      const data = await insertContactsList(
        auth, 
        idContactsList,
        idBrand, 
        name,
        idContactType
      );

      if(data.status === 'ok') {

        if(data.new_contact_list_id) {

          setidContactsList(data.new_contact_list_id);
          navigate(`/contacts-list/edit/${data.new_contact_list_id}`);
          setError(null);
          setSuccess('Se generó la lista con éxito.');
          
        }else{
          
          setError(null);
          setSuccess('Se actualizó la lista con éxito.');

        }

      }
      

    } catch (error) {

      if (!error?.response) {
        setError('No Server Response');
      } else if (error.response?.status === 400) {
          setError('Missing Username or Password');
      } else if (error.response?.status === 401) {
          setError('Unauthorized');
      } else {
          setError('Login Failed');
      }
      
    }

  }


  return (

    <>

    <Header />

    <div className="content">

      <h2 className="page_title">Alta de lista de destinatarios</h2>


      <div className="form_div">

        <form className="form" action="" onSubmit={handleSubmit}>

          <input 
                  type="hidden" 
                  id="idContactsList" 
                  name="idContactsList" 
                  value={idContactsList}
                  readOnly
                />


          <div className="form_row two">

            <div className="field">
                <label htmlFor="idBrand">Marca</label>
                <select value={idBrand}
                  ref={idBrandRef}
                  id="idBrand" 
                  onChange={(e) => setIdBrand(e.target.value)}
                >
                  <option value="0"></option>
                  
                {!isLoading && idBrands && idBrands?.map((option, index) => (
                  <option key={index} value={option.id}>
                    {option.name}
                  </option>
                ))}
                
                </select>
            </div>

            <div className="field">
                <label htmlFor="name">Nombre</label>
                <input 
                  ref={nameRef}
                  type="text" 
                  id="name" 
                  autoComplete="off"  
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  required
                />
            </div>

          </div>

          <div className="form_row two">

            <div className="field">
                <label htmlFor="idContactType">Tipo de usuario</label>
                <select value={idContactType}
                  ref={idContactTypeRef}
                  id="idContactType" 
                  onChange={(e) => setIdContactType(e.target.value)}
                >
                  <option value="0"></option>
                  
                {!isLoading && idContactTypes && idContactTypes?.map((option, index) => (
                  <option key={index} value={option.id}>
                    {option.name}
                  </option>
                ))}
                
                </select>
            </div>


          </div>

          <p className={error ? "errmsg" : "hidden"}>{error}</p>
          <p className={success ? "successmsg" : "hidden"}>{success}</p>

          <div className="buttons">

              <button className="button">Grabar</button>

          </div>

        </form>

      </div>


    </div>



    </>
    
    )
}

export default ContactsListForm