import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const instance = axios.create({

  baseURL: API_URL,

})

export const getContacts = async (params) => {

  const { data } = await instance.get('dashboard/contacts', { 'params': params });
  return data;

};

export const getPets = async (params) => {

  const { data } = await instance.get('dashboard/pets', { 'params': params });
  return data;

};


export const getEmailContacts = async (params) => {

  const { data } = await instance.get('dashboard/email-contacts', { 'params': params });
  return data;

};

export const getSources = async (params) => {

  const { data } = await instance.get('dashboard/sources', { 'params': params });
  return data;

};

export const getStates = async (params) => {

  const { data } = await instance.get('dashboard/states', { 'params': params });
  return data;

};

export const getContactsBySources = async (params) => {

  const { data } = await instance.get('dashboard/contact-sources', { 'params': params });
  return data;

};
