import RequireAuth from './components/RequireAuth';
import PersistLogin from './components/PersistLogin';
import { Routes, Route, Navigate } from "react-router-dom"

import Layout from './components/Layout';
import LogIn from "./pages/account/Login"
import PasswordRecovery from './pages/account/PasswordRecovery';
import PasswordReset from './pages/account/PasswordReset';
import Unauthorized from './pages/unauthorized/Unauthorized';


// Dashboard
import Dashboard from "./pages/dashboard/Dashboard"
import DashboardContacts from "./pages/dashboard/DashboardContacts"
import DashboardPetOwners from "./pages/dashboard/DashboardPetOwners"
import DashboardPets from "./pages/dashboard/DashboardPets"

// Campañas
import Campaigns from "./pages/campaigns/Campaigns"
import CampaignForm from "./pages/campaign/CampaignForm"

// Listas
import ContactsLists from "./pages/list/ContactsLists"
import ContactsListForm from "./pages/list/ContactsListForm"

// Envíos
import Emails from "./pages/emails/Emails"
import EmailDetail from "./pages/email/EmailDetail"
import EmailForm from "./pages/email/EmailForm"

// Destinatartios
import Contacts from "./pages/contacts/Contacts"

// Journeys
import Journeys from "./pages/journey/Journeys"
import JourneyForm from "./pages/journey/JourneyForm"

// Usuarios backend
import Users from './pages/user/Users';
import UserForm from './pages/user/UserForm';

const RedirectToDashboard = () => {
  // Realizar la redirección a la ruta de destino
  return <Navigate to="/dashboard" />;
};

const App = () =>  {

    return (
        <Routes>

          <Route path="/" element={<Layout />}>

            {/* rutas publicas */}s
            <Route path="login" element={<LogIn />} />
            <Route path="password-recovery" element={<PasswordRecovery />} />
            <Route path="password-reset/:hash" element={<PasswordReset />} />
            <Route path="unauthorized" element={<Unauthorized />} />
            
            <Route element={<PersistLogin />}>

              {/* rutas privadas */}
              <Route element={<RequireAuth allowedRoles={['admin']} />}>
                
                  <Route path="/" element={<RedirectToDashboard />} />
                  
                  {/* Dashboard */}
                  <Route path="dashboard" element={<Dashboard />} />
                  <Route path="dashboard/contacts" element={<DashboardContacts />} />
                  <Route path="dashboard/pet-owners" element={<DashboardPetOwners />} />
                  <Route path="dashboard/pets" element={<DashboardPets />} />
                  
                  
                  {/* Campañas */}
                  <Route path="campaigns" element={<Campaigns />} />
                  <Route path="campaigns/edit" element={<CampaignForm />} />
                  <Route path="campaigns/edit/:prev_idCampaign" element={<CampaignForm />} />

                  {/* Envíos */}
                  <Route path="emails" element={<Emails />} />
                  <Route path="emails/:idEmail" element={<EmailDetail />} />

                  <Route path="emails/edit" element={<EmailForm />} />
                  <Route path="emails/edit/:prev_idEmail" element={<EmailForm />} />
                  
                  {/* Destinatarios */}
                  <Route path="contacts" element={<Contacts />} />

                  {/* Listas de destinatarios */}
                  <Route path="contacts-lists" element={<ContactsLists />} />
                  <Route path="contacts-lists/edit" element={<ContactsListForm />} />
                  <Route path="contacts-lists/edit/:prev_idContactsList" element={<ContactsListForm />} />
                  

                  {/* Journeys */}
                  <Route path="journeys" element={<Journeys />} />
                  <Route path="journeys/edit" element={<JourneyForm />} />
                  {/* <Route path="campaigns/:idCampaign" element={<EmailDetail />} /> */}
                  

                  {/* Usuarios backend */}
                  <Route path="users" element={<Users />} />
                  <Route path="users/edit" element={<UserForm />} />
                  <Route path="users/edit/:prev_idUser" element={<UserForm />} />
                  
                  
              </Route>
              
              {/* rutas privadas */}
              <Route element={<RequireAuth allowedRoles={['superadmin']} />}>
                  <Route path="journeys" element={<Journeys />} />
              </Route>
              
            </Route>
            
            {/* catch all */}
            <Route path="*" element={<div>404 Not Found</div>} />

          </Route>

        </Routes>
    )

}

export default App