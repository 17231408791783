import { useRef, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom"
import { getBrands, getCampaign, insertCampaign } from "../../api/axios";
import Header from "../../components/Header";
import useAuth from "../../hooks/useAuth";

const CampaignForm = () => {

  const navigate = useNavigate();

  const { prev_idCampaign } = useParams();
  
  const [isLoading, setIsLoading] = useState(true);

  const [idCampaign, setIdCampaign] = useState(prev_idCampaign);

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  // CAMPOS
  const idBrandRef = useRef();
  const [idBrand, setIdBrand] = useState(0);
  const [idBrands, setIdBrands] = useState([]);
  
  const nameRef = useRef();
  const [name, setName] = useState('');

  const yearRef = useRef();
  const [year, setYear] = useState('');

  const { auth } = useAuth();

  useEffect(() => {

    const fetchData = async () => {
      
      setIsLoading(true);
      
      try {
        
        let data = await getBrands({ auth });
        setIdBrands(data.results);

        if(idCampaign) {

          data = await getCampaign(idCampaign, auth);
          
          if(data?.results) {

            setIdBrand(data.results.brand_id ?? 0);

            setName(data.results.name ?? '');
            setYear(data.results.year ?? '');

          }

        }

      } catch (error) {

        setError(error);
        setIdBrands(null);

      }

      setIsLoading(false);

    };

    fetchData();

  }, []);



  const handleSubmit = async (e) => {

    e.preventDefault();
    
    try {
      
      const data = await insertCampaign(
        auth,
        idCampaign,
        idBrand, 
        name, 
        year
      );

      if(data.status === 'ok') {

        if(data.new_campaign_id) {

          setIdCampaign(data.new_campaign_id);
          navigate(`/campaigns/edit/${data.new_campaign_id}`);
          setError(null);
          setSuccess('Se generó la campaña con éxito.');
          
        }else{
          
          setError(null);
          setSuccess('Se actualizó la campaña con éxito.');

        }

      }
      

    } catch (error) {

      if (!error?.response) {
        setError('No Server Response');
      } else if (error.response?.status === 400) {
          setError('Missing Username or Password');
      } else if (error.response?.status === 401) {
          setError('Unauthorized');
      } else {
          setError('Login Failed');
      }
      
    }

  }


  return (

    <>

    <Header />

    <div className="content">

      <h2 className="page_title">Alta de campaña</h2>


      <div className="form_div">

        <form className="form" action="" onSubmit={handleSubmit}>

        <input 
                  type="hidden" 
                  id="idCampaign" 
                  name="idCampaign" 
                  value={idCampaign}
                />


          <div className="form_row two">

            <div className="field">
                <label htmlFor="idBrand">Marca</label>
                <select value={idBrand}
                  ref={idBrandRef}
                  id="idBrand" 
                  onChange={(e) => setIdBrand(e.target.value)}
                >
                  <option value="0"></option>
                  
                {!isLoading && idBrands && idBrands?.map((option, index) => (
                  <option key={index} value={option.id}>
                    {option.name}
                  </option>
                ))}
                
                </select>
            </div>

            <div className="field">
                <label htmlFor="email">Nombre</label>
                <input 
                  ref={nameRef}
                  type="text" 
                  id="name" 
                  autoComplete="off"  
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  required
                />
            </div>

          </div>

          <div className="form_row two">

            <div className="field">
                <label htmlFor="year">Año</label>
                <input 
                  ref={yearRef}
                  type="number" 
                  id="year" 
                  autoComplete="off"  
                  onChange={(e) => setYear(e.target.value)}
                  value={year}
                  required
                />
            </div>


          </div>

          <p className={error ? "errmsg" : "hidden"}>{error}</p>
          <p className={success ? "successmsg" : "hidden"}>{success}</p>

          <div className="buttons">

              <button className="button" type="submit">Grabar</button>

          </div>

        </form>

      </div>


    </div>



    </>
    
    )
}

export default CampaignForm