import { Link } from "react-router-dom"
import { getUsers } from "../../api/axios"
import { useState, useEffect } from "react";
import useAuth from "../../hooks/useAuth";

const UserTable = (props) => {

const [isLoading, setIsLoading] = useState(true);
const [error, setError] = useState(null);
const [users, setUsers] = useState([]);

// https://www.robinwieruch.de/react-hooks-fetch-data/

const { auth } = useAuth();

useEffect(() => {

  let isMounted = true;
  const controller = new AbortController();

  const fetchData = async () => {
      
    setIsLoading(true);
    
    try {
      
      const data = await getUsers({props, auth, signal: controller.signal});
      isMounted && setUsers(data.results);

      setError(null);

    } catch (error) {

      setError(error);
      setUsers(null);

    }

    setIsLoading(false);

  };

  fetchData();

  return () => { 
    isMounted = false;
    controller.abort();
  }
    
}, [props, auth]);

  


  return (

    <>
          
      <table className="table">
                      
        <thead>
            <tr>
                <td>#</td> 
                <td>Nombre</td> 
                <td>Apellido</td> 
                <td>Email</td> 
                <td>Empresa</td> 
                <td>Perfil</td> 
            </tr>
        </thead>
        
        <tbody>
            {isLoading ? (
              
              <tr><td colSpan="6" className="c loading">Cargando...</td></tr>

            ) : !users?.length ? (

              <tr><td colSpan="6" className="c loading">No se encontraron usuarios</td></tr>

            ) : users.map( item => (

              <tr key={item.id}>
                  
                  <td className="c light">
                    <Link className="vinculo" to={`/users/edit/${item.id}`}>{item.id}</Link>
                  </td>
                  <td>
                    {item.name}
                  </td>
                  <td>
                    {item.last_name}
                  </td>
                  <td>
                    {item.email}
                  </td>
                  <td>
                    {item.company}
                  </td>
                  <td>
                    {item.profile_name}
                  </td>
              </tr>
            ))}
            
        </tbody>

      </table>
    </>
    
    )
}

export default UserTable