export const day = (dateRaw) => {

    const dateTime = new Date(dateRaw);

    const year = dateTime.getYear().toString().slice(-2);
    const month = (dateTime.getMonth() + 1).toString().padStart(2, '0');
    const day = dateTime.getDate().toString().padStart(2, '0');

    return dateRaw ? `${day}/${month}/${year}` : '';
        
};
 
export const dayTime = (dateRaw) => {

    if(dateRaw === '') {
        
        console.log(dateRaw);
        console.log('entrer');
        return '';
    
    }else{

        console.log(dateRaw);
        console.log('entrer222');
    }

    const date = day(dateRaw);

    const dateTime = new Date(dateRaw);

    const hours = dateTime.getHours().toString().padStart(2, '0');
    const minutes = dateTime.getMinutes().toString().padStart(2, '0');

    return `${date} ${hours}:${minutes}`
    
};

export const perc = (p100,px) => {

    return px !== 0 && p100 !== 0 ? Math.round(100*px/p100)+'%' : '-';
    
};

export const validatePassword = (cadena) => {
    
    if (/^(?=.*\d)(?=.*[A-Z\W]).{12,}$/.test(cadena)) {
        return true;
    } else {
        return false;
    }

}

export const validateDate = (cadena) => {
    
    const regexFecha = /^\d{4}-\d{2}-\d{2}$/;
    return regexFecha.test(cadena);

}

export const nf = (number) => {

    const formattedNumber = parseFloat(number).toLocaleString('es-ES', {
        style: 'decimal',
        useGrouping: true
    });

    return formattedNumber

}