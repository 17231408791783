import { useState } from "react";

import Header from "../../components/Header"
import DashboardFilters from "./DashboardFilters"
import DashboardPetOwnersTable from "./DashboardPetOwnersTable"

const DashboardPetOwners = () => {

    const [idBrand, setIdBrand] = useState(0);
    const [idContactType, setIdContactType] = useState(0);
    const [idConsent, setIdConsent] = useState(0);
    const [dateFrom, setDateFrom] = useState('');
    const [dateUntil, setDateUntil] = useState('');

    return (
        <>
            <Header />
            
            <div className="content">

                <div className="table_div">

                <DashboardFilters setIdBrand={setIdBrand} setIdContactType={setIdContactType} setDateFrom={setDateFrom} setDateUntil={setDateUntil} setIdConsent={setIdConsent}  />

                </div>

                <DashboardPetOwnersTable params={ { idBrand, idContactType, dateFrom, dateUntil, idConsent } } />

            </div>

        </>
    )
}

export default DashboardPetOwners