import { useState, useEffect } from "react";
import { useParams } from "react-router-dom"
import { getEmail } from "../../api/axios"
import { day, perc } from "../../utilities/Utils"
import useAuth from "../../hooks/useAuth";

const EmailInfo = (props) => {

  const { idEmail } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const [email, setEmail] = useState({});

  const { auth } = useAuth();

useEffect(() => {

    const fetchData = async () => {
      
      setIsLoading(true);
      
      try {

        if(idEmail) {
          
          const data = await getEmail(idEmail, auth);
          setEmail(data.results);
          setError(null);

        }
  
  
      } catch (error) {
  
        setError(error);
        setEmail(null);
  
      }
  
      setIsLoading(false);
  
    };
  
    fetchData();
      
  }, [idEmail, auth]);

  
  if(!email) {

    return (<div className="no_results">No se encontró el envío</div>);

  }


  return (

    <div className="info_div">

        <h2 className="strong">{email.subject}</h2>

        <ul>
            <li>
                <h4>Enviados</h4>
                <span className="strong valor">{email.sent} </span>
            </li>
            <li>
                <h4>Abiertos</h4>
                <span className="strong valor">{email.opened}</span><br/>
                <span className="light">{perc(email.sent,email.opened)}</span>
            </li>
            <li>
                <h4>Clicks</h4>
                <span className="strong valor">{email.clicks}</span><br/>
                <span className="light">{perc(email.sent,email.clicks)}</span>
            </li>
            <li>
                <h4>Rebotados</h4>
                <span className="strong valor">{email.bounced}</span><br/>
                <span className="light">{perc(email.sent,email.bounced)}</span>
            </li>
            <li>
                <h4>Bajas</h4>
                <span className="strong valor baja">{email.unsubscribed}</span><br/>
                <span className="light">{perc(email.sent,email.unsubscribed)}</span>
            </li>
        </ul>
    </div>
    
    
    )
}

export default EmailInfo