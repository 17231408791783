import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
// import useRefreshToken from '../hooks/useRefreshToken';
import useAuth from '../hooks/useAuth';

// aca explica el login persistente
// React Persistent User Login Authentication with JWT Tokens
// https://www.youtube.com/watch?v=27KeYk-5vJw
// Otros temas: logout

// React Login Authentication with JWT Access, Refresh Tokens, Cookies and Axios
// ACA METE TAMBIEN AXIOSPRIVATE Y EL BEARER
// https://www.youtube.com/watch?v=nI8PYZNFtac

const PersistLogin = () => {
  const [isLoading, setIsLoading] = useState(true);
  // const refresh = useRefreshToken();
  const { auth } = useAuth();

  useEffect(() => {

  //   const verifyRefreshToken = async () => {
  //     try {
  //       await refresh();
  //     } 
  //     catch (err) {
  //       console.log(err);
  //     }
  //     finally {
  //       setIsLoading(false);
  //     }
  //   }

  //   // !auth?.accessToken ? verifyRefreshToken() : setIsLoading(false);

  setIsLoading(false);

  }, []);


  // useEffect(() => {

  //   console.log(`isLoading: ${isLoading}`);
  //   console.log(`aT: ${JSON.stringify(auth?.accessToken)}`);

  // },[isLoading])


  return (
    <>
      {isLoading
        ? <p>Loading...</p>
        : <Outlet />
      }
    </>
  )
}

export default PersistLogin