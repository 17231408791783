import { useState, useEffect } from "react";
import Header from "../../components/Header"
import ContactsTable from "./ContactsTable"

const Contacts = () => {

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState('');


  return (
    
    <>

      <Header />
              
      <div className="content">

      <h2 className="page_title">Destinatarios</h2>

        <div className="table_div">

          <div className="filters_div">

            <ul>
              <li>
                <label htmlFor="email">
                  <span>Email</span>
                  <input id="email" type="text" onChange={(event)=>{setEmail(event.target.value)}} />
                </label> 
              </li>
            </ul>

          </div>

          <ContactsTable params={ { email } } />

        </div>

      </div>

    </>

    
  )
}

export default Contacts