import { useRef, useState, useEffect } from "react";
import { getBrands } from "../../api/axios"
import Header from "../../components/Header"
import JourneyTables from "./JourneysTable"
import useAuth from "../../hooks/useAuth";

const Journeys = () => {

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const idBrandRef = useRef();
  const [idBrand, setIdBrand] = useState(0);
  const [idBrands, setIdBrands] = useState([]);

  const [name, setName] = useState('');

  // https://www.robinwieruch.de/react-hooks-fetch-data/

  const { auth } = useAuth();

  useEffect(() => {

    const fetchData = async () => {
      
      setIsLoading(true);
      
      try {
        
        let data = await getBrands({ auth });
        setIdBrands(data.results);

        setError(null);

      } catch (error) {

        setError(error);
        setIdBrands(null);

      }

      setIsLoading(false);

    };

    fetchData();
    
  }, [auth]);


  
  const handleSubmit = (event) => {

    event.preventDefault();

    setIdBrand(event.target.idBrand.value);
    setName(event.target.name.value);
    
  }

  return (

    <>

      <Header />
              
        <div className="content">

          <h2 className="page_title">Journeys</h2>

          <div className="table_div">


            <div className="filters_div">

              <form action="" onSubmit={handleSubmit}>

                <ul>

                  <li>
                    <label htmlFor="idBrand">
                      <span>Marca</span>
                      <select 
                        ref={idBrandRef}
                        id="idBrand" 
                        name="idBrand" 
                      >
                        <option value="0"></option>
                        
                      {!isLoading && idBrands && idBrands?.map((option, index) => (
                        <option key={index} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                      
                      </select>
                    </label>
                  </li>
                  
                  <li>
                    <label htmlFor="name">
                      <span>Nombre</span>
                      <input 
                        name="name" 
                        type="text"                         
                      />
                    </label> 
                  </li>
                  <li>
                    <button className="button-outline" type="submit">Buscar</button>
                  </li>
                </ul>

              </form>

            </div>

            <JourneyTables params={ { idBrand, name } } />

          </div>

        </div>

    </>
    
    )
}

export default Journeys