import { getContacts, getSources, getStates } from "../../api/dashboard";
import { useState, useEffect } from "react";
import { nf } from "../../utilities/Utils"

const DashboardPetOwnersTable = (props) => {

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingSources, setIsLoadingSources] = useState(true);
  const [error, setError] = useState(null);

  const [pet_owners,      setPetOwners]       = useState(0);
  const [dog_owners,      setDogOwners]       = useState(0);
  const [cat_owners,      setCatOwners]       = useState(0);
  const [dog_cat_owners,  setDogCatOwners]    = useState(0);
  
  const [cat_kitten_owners, setCatKittenOwners] = useState(0);
  const [cat_adult_owners,  setCatAdultOwners]  = useState(0);
  const [cat_mature_owners, setCatMatureOwners] = useState(0);
  const [cat_senior_owners, setCatSeniorOwners] = useState(0);
  
  const [dog_xsmall_owners, setDogXsmallOwners] = useState(0);
  const [dog_small_owners,  setDogSmallOwners]  = useState(0);
  const [dog_medium_owners, setDogMediumOwners] = useState(0);
  const [dog_large_owners,  setDogLargeOwners]  = useState(0);
  const [dog_giant_owners,  setDogGiantOwners]  = useState(0);
  
  const [dog_puppy_owners,  setDogPuppyOwners] = useState(0);
  const [dog_adult_owners,  setDogAdultOwners]  = useState(0);
  const [dog_mature_owners, setDogMatureOwners] = useState(0);
  const [dog_senior_owners, setDogSeniorOwners] = useState(0);

  

  // FUENTES
  const [sources, setSources]           = useState([]);
  const [activations, setActivations]   = useState([]);
  const [events, setEvents]             = useState([]);

  // PROVINCIAS
  const [states, setStates]             = useState([]);


  // FUNCIONES VIEJAS 
  const [detalleActivo, setDetalleActivo] = useState('activaciones');

  const [detalleFuenteActivo, setDetalleFuenteActivo] = useState('activaciones');
  const [detalleProvinciaActivo, setDetalleProvinciaActivo] = useState('CABA');
  
  const handleActivacionesClick = () => {

    setDetalleFuenteActivo('activaciones');

  }
  
  const handleEventosClick = () => {

    setDetalleFuenteActivo('eventos');

  }

  const handleProvinciaClick = (provincia) => {

    setDetalleProvinciaActivo(provincia);

  }

  useEffect(() => {

    const fetchData = async () => {
      
      setIsLoading(true);
      
      try {
        
        let data = 0;

        //////////////////////////////
        // CONTACTOS
        //////////////////////////////

        // PET OWNERS
        data = await getContacts({ ...props.params, 'idContactType': 1 });
        setPetOwners(data.count);

        data = await getContacts({ ...props.params, 'idContactType': 1, 'hasDog': true });
        setDogOwners(data.count);

        data = await getContacts({ ...props.params, 'idContactType': 1, 'hasCat': true });
        setCatOwners(data.count);

        data = await getContacts({ ...props.params, 'idContactType': 1, 'hasCat': true, 'hasDog': true });
        setDogCatOwners(data.count);

        // FUENTES
        data = await getSources({ 'idParentSource': 0 });
        setSources(data.results);

        // ACTIVACIONES
        data = await getSources({ 'idParentSource': 11 });
        setActivations(data.results);

        // EVENTOS
        data = await getSources({ 'idParentSource': 6 });
        setEvents(data.results);
        
        // PROVINCIAS
        data = await getStates();
        setStates(data.results);



        

        // GATO - ETAPA DE VIDA 
        data = await getContacts({ ...props.params, 'hasCatKitten': true });
        setCatKittenOwners(data.count);

        data = await getContacts({ ...props.params, 'hasCatAdult': true });
        setCatAdultOwners(data.count);

        data = await getContacts({ ...props.params, 'hasCatMature': true });
        setCatMatureOwners(data.count);

        data = await getContacts({ ...props.params, 'hasCatSenior': true });
        setCatSeniorOwners(data.count);


        // PERRO - TAMAÑO
        data = await getContacts({ ...props.params, 'hasDogXsmall': true });
        setDogXsmallOwners(data.count);

        data = await getContacts({ ...props.params, 'hasDogSmall': true });
        setDogSmallOwners(data.count);

        data = await getContacts({ ...props.params, 'hasDogMedium': true });
        setDogMediumOwners(data.count);

        data = await getContacts({ ...props.params, 'hasDogLarge': true });
        setDogLargeOwners(data.count);

        data = await getContacts({ ...props.params, 'hasDogGiant': true });
        setDogGiantOwners(data.count);


        // PERRO - ETAPA DE VIDA
        data = await getContacts({ ...props.params, 'hasDogPuppy': true });
        setDogPuppyOwners(data.count);

        data = await getContacts({ ...props.params, 'hasDogAdult': true });
        setDogAdultOwners(data.count);

        data = await getContacts({ ...props.params, 'hasDogMature': true });
        setDogMatureOwners(data.count);

        data = await getContacts({ ...props.params, 'hasDogSenior': true });
        setDogSeniorOwners(data.count);


        setError(null);

      } catch (error) {

        setError(error);

      }

      setIsLoading(false);

    };

    fetchData();
    
  }, [props]);
    


  const [sourcesCant, setSourcesCant] = useState({});
  const [statesCant, setStatesCant] = useState({});

  const getContactsBySource = async (idSource) => {
        
    try {
        
        let data = await getContacts( { ...props.params, idSource, 'hasDog': true } );
        setSourcesCant(prevContacts => ({ ...prevContacts, [idSource]: { ...prevContacts[idSource], 'perros' : data.count } }));

        data = await getContacts( { ...props.params, idSource, 'hasCat': true } );
        setSourcesCant(prevContacts => ({ ...prevContacts, [idSource]: { ...prevContacts[idSource], 'gatos' : data.count } }));

    } catch (error) {

        console.log(error);
        return 'Error';

    }

  };

  const getContactsByState = async (idState) => {
        
    try {
        
        let data = await getContacts( { ...props.params, idState, 'hasDog': true } );
        setStatesCant(prevContacts => ({ ...prevContacts, [idState]: { ...prevContacts[idState], 'perros' : data.count } }));

        data = await getContacts( { ...props.params, idState, 'hasCat': true } );
        setStatesCant(prevContacts => ({ ...prevContacts, [idState]: { ...prevContacts[idState], 'gatos' : data.count } }));

    } catch (error) {

        console.log(error);
        return 'Error';

    }

  };

  useEffect(() => {

    sources.forEach(source => {
        getContactsBySource(source.id);
    });
  
  }, [sources]);

  useEffect(() => {
    
    activations.forEach(source => {
      
      getContactsBySource(source.id);
    });

  }, [activations]);

  useEffect(() => {

      events.forEach(source => {
        getContactsBySource(source.id);
      });

  }, [events]);

  useEffect(() => {

    states.forEach(state => {
      getContactsByState(state.id);
    });

}, [states]);



    return (
        <>

          <div className="flex space-around">

              <div>

                  <h2 className="page_title">Dueños de mascota</h2>

                  <table className="table pd10 mb--50">

                      <tbody>
                      <tr>
                          <td className="bold">Total Dueños</td>
                          <td className="r">{ nf(pet_owners) }</td>
                          <td className="bold">Dueños Ambas</td>
                          <td className="r">{ nf(dog_cat_owners) }</td>
                      </tr>
                      <tr>
                          <td className="bold">Dueños perros</td>
                          <td className="r">{ nf(dog_owners) }</td>
                          <td className="bold">Dueños gatos</td>
                          <td className="r">{ nf(cat_owners) }</td>
                      </tr>
                      </tbody>
                  </table>

                  
                  <h2 className="page_title">Fuentes</h2>

                  <table className="table pd10 mb--50">
                      
                      <thead>
                          <tr>
                              <td>Fuente</td>
                              <td>Dueños perros</td>
                              <td>Dueños gatos</td>
                          </tr>
                      </thead>

                      <tbody>
                          { sources.map( item => (
                          
                          <tr key={item.id}>
                              <td className="bold">{ item.name } { item.id }</td>
                              <td className="r">{ nf(sourcesCant[item.id]?.perros) }</td>
                              <td className="r">{ nf(sourcesCant[item.id]?.gatos) }</td>
                          </tr>

                          ))}
                      </tbody>
                  </table>


              { detalleFuenteActivo == 'activaciones' && 
              <div className="mt--30">

                  <h2 className="page_title">Activaciones</h2>

                  <table className="table pd10">

                      <thead>
                          <tr>
                              <td>Activación</td>
                              <td>Dueños perros</td>
                              <td>Dueños gatos</td>
                          </tr>
                      </thead>
                      
                      <tbody>
                      { activations.map( item => (
                          
                        <tr key={item.id}>
                            <td className="bold">{ item.name }</td>
                            <td className="r">{ nf(sourcesCant[item.id]?.perros) }</td>
                            <td className="r">{ nf(sourcesCant[item.id]?.gatos) }</td>
                        </tr>

                      ))}
                      </tbody>
                  </table>



              </div>
              }

              

              { detalleFuenteActivo == 'activaciones' && 
              <div className="mt--30">

                  <h2 className="page_title">Eventos</h2>

                  <table className="table pd10">

                      <thead>
                          <tr>
                              <td>Evento</td>
                              <td>Dueños perros</td>
                              <td>Dueños gatos</td>
                          </tr>
                      </thead>
                      
                      <tbody>
                      { events.map( item => (
                          
                        <tr key={item.id}>
                            <td className="bold">{ item.name }</td>
                            <td className="r">{ nf(sourcesCant[item.id]?.perros) }</td>
                            <td className="r">{ nf(sourcesCant[item.id]?.gatos) }</td>
                        </tr>

                      ))}
                      </tbody>

                  </table>



              </div>
              }


              </div>

              <div>
              
                  <h2 className="page_title">Etapas de vida</h2>

                  <div className="flex space-between cg--30 mb--50">

                    <div>

                      <table className="table pd10">
                          
                          <thead>
                              <tr>
                                  <td>Etapa</td>
                                  <td>Dueños Perro</td>
                              </tr>
                          </thead>

                          <tbody>
                            <tr>
                                <td className="bold">Cachorro</td>
                                <td className="r">{ nf(dog_puppy_owners) }</td>
                            </tr>
                            <tr>
                                <td className="bold">Adulto</td>
                                <td className="r">{ nf(dog_adult_owners) }</td>
                            </tr>
                            <tr>
                                <td className="bold">Maduro</td>
                                <td className="r">{ nf(dog_mature_owners) }</td>
                            </tr>
                            <tr>
                                <td className="bold">Senior</td>
                                <td className="r">{ nf(dog_senior_owners) }</td>
                            </tr>
                          </tbody>
                      </table>



                    </div>

                    <div>

                      <table className="table pd10">
                          
                          <thead>
                              <tr>
                                  <td>Etapa</td>
                                  <td>Duenos Gato</td>
                              </tr>
                          </thead>

                          <tbody>
                            <tr>
                                <td className="bold">Gatito</td>
                                <td className="r">{ nf(cat_kitten_owners) }</td>
                                
                            </tr>
                            <tr>
                                <td className="bold">Adulto</td>
                                <td className="r">{ nf(cat_adult_owners) }</td>
                                
                            </tr>
                            <tr>
                                <td className="bold">Maduro</td>
                                <td className="r">{ nf(cat_mature_owners) }</td>
                                
                            </tr>
                            <tr>
                                <td className="bold">Senior</td>
                                <td className="r">{ nf(cat_senior_owners) }</td>
                                
                            </tr>
                          </tbody>
                      </table>
                    </div>

                  </div>

                  <h2 className="page_title">Tamaño</h2>
                  
                  <table className="table pd10">
                          
                          <thead>
                              <tr>
                                  <td>Talla</td>
                                  <td>Dueños Perro</td>
                              </tr>
                          </thead>

                          <tbody>
                            <tr>
                                <td className="bold">Muy Pequeña</td>
                                <td className="r">{ nf(dog_xsmall_owners) }</td>
                            </tr>
                            <tr>
                                <td className="bold">Pequeña</td>
                                <td className="r">{ nf(dog_small_owners) }</td>
                            </tr>
                            <tr>
                                <td className="bold">Mediana</td>
                                <td className="r">{ nf(dog_medium_owners) }</td>
                            </tr>
                            <tr>
                                <td className="bold">Grande</td>
                                <td className="r">{ nf(dog_large_owners) }</td>
                            </tr>
                            <tr>
                                <td className="bold">Gigante</td>
                                <td className="r">{ nf(dog_giant_owners) }</td>
                            </tr>
                          </tbody>
                      </table>

                  


              </div>

              <div>

                  <h2 className="page_title">Distribución geográfica</h2>

                  <table className="table pd10 mb--50">
                      
                      <thead>
                          <tr>
                              <td>Provincia</td>
                              <td>Dueños perros</td>
                              <td>Dueños gatos</td>
                          </tr>
                      </thead>

                      <tbody>
                      { states.map( item => (
                        
                        <tr key={item.id}>
                              <td className="bold">{ item.name }</td>
                              <td className="r">{ nf(statesCant[item.id]?.perros) }</td>
                              <td className="r">{ nf(statesCant[item.id]?.gatos) }</td>
                          </tr>
  
                      ))}
                      </tbody>

                  </table>


              </div>

          </div>


        </>
    )
}

export default DashboardPetOwnersTable