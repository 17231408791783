import React from "react";
import ReactDom from "react-dom/client";
import "./assets/sass/styles.css" 
import App from "./App";
import { AuthProvider } from './context/AuthProvider'
import { BrowserRouter, Routes, Route } from 'react-router-dom';

const root = ReactDom.createRoot(document.getElementById("root"));

root.render(
<React.StrictMode>
  <BrowserRouter>
    <AuthProvider>
      <Routes>
        <Route path="/*" element={<App />} />
      </Routes>
    </AuthProvider>
  </BrowserRouter>
</React.StrictMode>
);