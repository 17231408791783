import { useParams } from "react-router-dom"
import { useState } from "react";
import EmailContactsTable from "./EmailContactsTable"
import EmailInfo from "./EmailInfo"
import Header from "../../components/Header";

const EmailDetail = () => {

  const { idEmail } = useParams();
  
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const [status, setPostfixStatus] = useState('');

  const [email, setEmail] = useState('');

  const handleSubmit = (event) => {

    event.preventDefault();

    setPostfixStatus(event.target.status.value);
    setEmail(event.target.email.value);

  }


  return (

    <>

    <Header />

    <div className="content">

      <div className="table_div">

        <EmailInfo idEmail={ idEmail } />

        <div className="filters_div">

        <form action="" onSubmit={handleSubmit}>

          <ul>
            <li>
              <label forhtml="status">
                <span>Estado</span>
                <select id="status">
                  <option value="0">Todos</option>
                  <option value="opened">Abierto</option>
                  <option value="unopened">No abierto</option>
                  <option value="bounced">Rebotado</option>
                  <option value="unsubscribed">Baja</option>
                </select>
              </label> 
            </li>
            <li>
              <label forhtml="email">
                <span>Email</span>
                <input id="email" type="text" />
              </label> 
            </li>
            <li>
              <button className="button-outline" type="submit">Buscar</button>
            </li>
          </ul>

          </form>

        </div>

        <EmailContactsTable params={ { idEmail, status, email } } />

      </div>

    </div>

    </>
    
    )
}

export default EmailDetail