import { getContacts, getSources, getContactsBySources } from "../../api/dashboard";
import { useState, useEffect } from "react";
import { nf } from "../../utilities/Utils"

const DashboardContactsTable = (props) => {

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const [detalleActivo, setDetalleActivo] = useState('activaciones');

    // CONTACTOS

    // TOTAL DE CONTACTOS
    const [contacts,    setContacts]    = useState(0);

    // POR TIPO DE CONTACTO
    const [pet_owners,  setPetOwners]   = useState(0);
    const [vets,        setVets]        = useState(0);
    const [breeders,    setBreeders]    = useState(0);
    const [students,    setStudents]    = useState(0);
    const [customers,   setCustomers]   = useState(0);
    const [pet_shops,   setPetShops]    = useState(0);

    // POR MARCA
    const [royal_canin, setRoyalCanin]  = useState(0);
    const [eukanuba,    setEukanuba]    = useState(0);
    const [mi_vet_shop, setMiVetShop]   = useState(0);
    
    // FUENTES
    const [sources, setSources]           = useState([]);
    const [activations, setActivations]   = useState([]);
    const [events, setEvents]             = useState([]);

  
    const handleActivacionesClick = () => {
        setDetalleActivo('activaciones');
    }
  
    const handleEventosClick = () => {
        setDetalleActivo('eventos');

    }

    useEffect(() => {

        const fetchData = async () => {
          
          setIsLoading(true);
          
          try {
            
            let data = 0;

            //////////////////////////////
            // CONTACTOS
            //////////////////////////////

            // TOTAL DE CONTACTOS
            data = await getContacts({ ...props.params });
            setContacts(data.count);

            // POR TIPO DE CONTACTO
            data = await getContacts({ ...props.params, 'idContactType': 1 });
            setPetOwners(data.count);

            data = await getContacts({ ...props.params, 'idContactType': 2 });
            setBreeders(data.count);

            data = await getContacts({ ...props.params, 'idContactType': 3 });
            setStudents(data.count);

            data = await getContacts({ ...props.params, 'idContactType': 4 });
            setVets(data.count);

            data = await getContacts({ ...props.params, 'idContactType': 5 });
            setPetShops(data.count);
    
            data = await getContacts({ ...props.params, 'idContactType': 6 });
            setCustomers(data.count);

            // POR MARCA
            data = await getContacts({ ...props.params, 'idBrand': 1 });
            setRoyalCanin(data.count);
    
            data = await getContacts({ ...props.params, 'idBrand': 2 });
            setEukanuba(data.count);
    
            data = await getContacts({ ...props.params, 'idBrand': 3 });
            setMiVetShop(data.count);

            // FUENTES
            data = await getSources({ 'idParentSource': 0 });
            setSources(data.results);

            // ACTIVACIONES
            data = await getSources({ 'idParentSource': 11 });
            setActivations(data.results);

            // EVENTOS
            data = await getSources({ 'idParentSource': 6 });
            setEvents(data.results);
    
            setError(null);
    
          } catch (error) {
    
            setError(error);
    
          }
    
          setIsLoading(false);
    
        };
    
        fetchData();
        
    }, [props]);


    const [sourcesCant, setSourcesCant] = useState({});

    const getContactsBySource = async (idSource) => {
            
        try {
            
            const data = await getContactsBySources({...props.params, idSource});
            setSourcesCant(prevContacts => ({ ...prevContacts, [idSource]: data.count }));

        } catch (error) {

            console.log(error);
            return 'Error';

        }

    };

    useEffect(() => {
    
        sources.forEach(source => {
            getContactsBySource(source.id);
        });
    
    }, [sources]);

    useEffect(() => {
    
        activations.forEach(source => {
          getContactsBySource(source.id);
        });

    }, [activations]);

    useEffect(() => {
    
        events.forEach(source => {
          getContactsBySource(source.id);
        });
    
    }, [events]);


    return (
        <>

          <div className="flex space-around">

              <div>

                  <h2 className="page_title">Contactos</h2>

                  <table className="table pd10">
                      
                      <thead>
                          <tr>
                              <td colSpan="2" className="r bold">Total contactos</td>
                              <td colSpan="2">{ nf(contacts) }</td>
                          </tr>
                      </thead>

                      <tbody>
                      <tr>
                          <td className="bold">Dueños de mascota</td>
                          <td className="r">{ nf(pet_owners) }</td>
                          <td className="bold">Criadores</td>
                          <td className="r">{ nf(breeders) }</td>
                      </tr>
                      <tr>
                          <td className="bold">Vets & Pet Shops directos</td>
                          <td className="r">{ nf(vets) }</td>
                          <td className="bold">Estudiantes</td>
                          <td className="r">{ nf(students) }</td>
                      </tr>
                      <tr>
                          <td className="bold">Vets & Pet Shops distribuidores</td>
                          <td className="r">{ nf(customers) }</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                      </tr>
                      </tbody>
                  </table>

                  <table className="mt--30 table pd10">
                      <tbody>
                          <tr>
                              <td className="bold">Royal Canin</td>
                              <td className="r">{ nf(royal_canin) }</td>
                          </tr>
                          <tr>
                              <td className="bold">Eukanuba</td>
                              <td className="r">{ nf(eukanuba) }</td>
                          </tr>
                      </tbody>
                  </table>


              </div>

              <div>
              
                  <h2 className="page_title">Fuentes</h2>


                  <table className="table pd10">
                      
                      <tbody>
                          { sources.map( item => (
                          
                          <tr key={item.id}>
                              <td className="bold">{ item.name }</td>
                              <td className="r">{ nf(sourcesCant[item.id]) }</td>
                          </tr>

                          ))}
                      </tbody>
                  </table>



              </div>

              { detalleActivo === 'activaciones' && 
              <div>

                  <h2 className="page_title">Activaciones</h2>

                  <table className="table pd10">

                      <tbody>

                          { activations.map( item => (
                          
                          <tr key={item.id}>
                              <td className="bold">{ item.name }</td>
                              <td className="r">{ nf(sourcesCant[item.id]) }</td>
                          </tr>

                          ))}

                      </tbody>
                  </table>

              </div>
              }

              

              { detalleActivo === 'activaciones' && 
              <div>

                  <h2 className="page_title">Eventos</h2>

                  <table className="table pd10">

                      <tbody>
                      { events.map( item => (
                          
                          <tr key={item.id}>
                              <td className="bold">{ item.name }</td>
                              <td className="r">{ nf(sourcesCant[item.id]) }</td>
                          </tr>

                      ))}
                      </tbody>
                  </table>



              </div>
              }



          </div>

        </>
    )
}

export default DashboardContactsTable