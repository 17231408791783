import { Link } from "react-router-dom"
import { getLists } from "../../api/axios"
import { useState, useEffect } from "react";
import { day, perc } from "../../utilities/Utils"
import useAuth from "../../hooks/useAuth";

const ListsTable = (props) => {

const [isLoading, setIsLoading] = useState(true);
const [error, setError] = useState(null);
const [lists, setLists] = useState([]);

const { auth } = useAuth();

useEffect(() => {

  const fetchData = async () => {
      
    setIsLoading(true);
    
    try {
      
      const data = await getLists({props, auth});
      setLists(data.results);
      setError(null);

    } catch (error) {

      setLists(null);

    }

    setIsLoading(false);

  };


  fetchData();
    
}, [props, auth]);

  


  return (

    <>
          
      <table className="table">
                      
        <thead>
            <tr>
                <td>#</td>
                <td>Marca</td> 
                <td>Tipo de destinatario</td> 
                <td>Nombre</td> 
                <td>Destinatarios</td> 
                <td>Fecha Actualización</td> 
                <td>Fecha Alta</td> 
            </tr>
        </thead>
        <tbody>
            {isLoading ? (
              
              <tr><td colSpan="7" className="c loading">Cargando...</td></tr>

            ) : !lists?.length ? (

              <tr><td colSpan="7" className="c loading">No se encontraron resultados</td></tr>

            ) : lists.map( item => (

              <tr key={item.id}>
                  
                  <td className="c light">
                    <Link className="vinculo" to={`/contacts-lists/edit/${item.id}`}>{item.id}</Link>
                  </td>
                  
                  <td>
                    <span>{item.brand_name}</span>
                  </td>

                  <td>
                    <span>{item.contact_type_name}</span>
                  </td>
                  
                  <td>
                    <span>{item.name}</span>
                  </td>
                  
                  <td className="c">
                    <span>{item.contacts}</span>
                  </td>
                  
                  <td className="c">
                    <span>{day(item.updated_at)}</span>
                  </td>
                  
                  <td className="c">
                    <span>{day(item.created_at)}</span>
                  </td>
                  
              </tr>
            ))}
            
        </tbody>

      </table>
    </>
    
    )
}

export default ListsTable