import { useRef, useState, useEffect } from "react";
import { NavLink } from "react-router-dom"

import { passwordRecovery } from "../../api/axios"
import Logo from "../../components/Logo";

const PasswordRecovery = () => {
    
  const emailRef = useRef();

  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(()=>{

    emailRef.current.focus();

  },[])

  const handleSubmit = async (e) => {

    e.preventDefault();
    
    try {
      
      const data = await passwordRecovery(email);

      if(data?.status === 'ok') {

        setSuccess('Si existe una cuenta asociada a esa dirección de email va a estar recibiendo un email para poder generar una nueva contraseña.');
        setEmail('');
        setError(null);

      }



    } catch (error) {

      if (!error?.response) {
        setError('No Server Response');
      } else if (error.response?.status === 400) {
        setError('Missing Username or Password');
      } else if (error.response?.status === 401) {
        setError('Unauthorized');
      } else {
        setError('Login Failed');
      }
      
    }

  }

  return (
    
        <div className="content white">

            <Logo />


            <form className="form login" onSubmit={handleSubmit}>

                <p className="mb--30"><span className="strong">¿Olvidaste tu contraseña?</span><br /><br />
                Ingresá la dirección de email asociada a tu cuenta y te enviaremos un email para que puedas generar una nueva contraseña.
                </p>

                <div className="field">
                    <label htmlFor="email">Email</label>
                    <input 
                      ref={emailRef}
                      type="text" 
                      id="email" 
                      autoComplete="off"  
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      required
                    />
                </div>

                <p className="center mb--30"><NavLink to="/login" className="underline">Acceso con usuario y contraseña</NavLink></p>

                <p className={error ? "errmsg" : "hidden"}>{error}</p>
                <p className={success ? "successmsg" : "hidden"}>{success}</p>

                <div className="buttons">

                    <button className="button">Enviar email</button>

                </div>

            </form>

        </div>
   
    )
}

export default PasswordRecovery