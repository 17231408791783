import { Link } from "react-router-dom"
import { getCampaigns } from "../../api/axios"
import { useState, useEffect } from "react";
import useAuth from "../../hooks/useAuth";


const CampaignsTable = (props) => {

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [campaigns, setCampaigns] = useState(null);

// https://www.robinwieruch.de/react-hooks-fetch-data/

  const { auth } = useAuth();

  useEffect(() => {

    const fetchData = async () => {
      
      try {
        
        const data = await getCampaigns({props, auth});
        setCampaigns(data.results);

        setError(null);

      } catch (error) {

        setError(error);
        setCampaigns(null);

      }

      setIsLoading(false); 

    };

    fetchData();

  }, [props, auth]);

  


  return (

    <>
      
      <table className="table">
                      
        <thead>
            <tr>
                <td>#</td> 
                <td>Año</td> 
                <td>Marca</td> 
                <td>Nombre</td> 
            </tr>
        </thead>
        <tbody>
            {isLoading ? (
              
              <tr><td colSpan="4" className="c loading">Cargando...</td></tr>

            ) : !campaigns?.length ? (

              <tr><td colSpan="4" className="c loading">No se encontraron campañas</td></tr>

            ) : campaigns.map( item => (

              <tr key={item.id}>
                  
                  <td className="c light">
                    <Link className="vinculo" to={`/campaigns/edit/${item.id}`}>{item.id}</Link>
                  </td>
                  <td>
                    <span>{item.year}</span>
                  </td>
                  <td>
                    <span>{item.brand_name}</span>
                  </td>
                  <td>
                    <span>{item.name}</span>
                  </td>
                  
              </tr>
            ))}
            
        </tbody>

      </table>
    </>
    
    )
}

export default CampaignsTable