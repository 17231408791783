import { Link } from "react-router-dom";
import logoCorpoSvg from '../assets/images/logo-corpo.svg';

function Logo() {
  return (
    <div className="logo">
        <Link to="/">
            <img src={logoCorpoSvg} alt="" />
        </Link>
    </div>
  )
}

export default Logo