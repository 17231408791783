import { useState, useEffect } from "react";
import { getEmailContacts } from "../../api/axios"
import { day } from "../../utilities/Utils"
import useAuth from "../../hooks/useAuth";

const EmailContactsTable = (props) => {

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const [campaign, setCampaign] = useState([]);

  const { auth } = useAuth();


  useEffect(() => {

    const idEmail = props.params.idEmail;

    const fetchData = async () => {
      
      setIsLoading(true);
      
      try {        
        
        const data = await getEmailContacts({idEmail, props, auth});
        setCampaign(data.results);
        setError(null);
  
      } catch (error) {
  
        setError(error);
        setCampaign(null);
  
      }
  
      setIsLoading(false);
  
    };

    fetchData();
      
  }, [props, auth]);



  return (

    <>
          
      <table className="table">
                      
        <thead>
            <tr>
                <td>#</td> 
                <td>Email</td> 
                <td className="c">Enviado</td> 
                <td className="c">Abierto</td> 
                <td className="c">Baja</td> 
                <td className="c">Estado</td> 
            </tr>
        </thead>
        <tbody>

            {isLoading ? (
              
              <tr><td colSpan="6" className="c loading">Cargando...</td></tr>

            ) : !campaign?.length ? (

              <tr><td colSpan="6" className="c loading">No se encontraron resultados</td></tr>

            ) : campaign.map( item => (
              
              <tr key={item.id}>
                  
                  <td className="c light">{item.id} </td>
                  <td className="vinculo">{item.email}</td>
                  {/* <td className="vinculo"><Link to={`/user/${item.contact_id}`}>{item.email}</Link></td> */}
                  <td className="c">{day(item.date_sent)}</td>
                  <td className="c">{day(item.date_opened)}</td>
                  <td className="c baja">{day(item.date_unsubscribed)}</td>
                  <td className="c">{item.postfix_status}</td>
                  
              </tr>

            ))}
            
        </tbody>

      </table>
    </>
    
    )
}

export default EmailContactsTable