import { Link } from "react-router-dom"
import { getEmails } from "../../api/axios"
import { useState, useEffect } from "react";
import { FaCode } from "react-icons/fa";
import { day, perc } from "../../utilities/Utils"
import useAuth from "../../hooks/useAuth";

const EmailsTable = (props) => {

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [emails, setEmails] = useState([]);

  const { auth } = useAuth();

  useEffect(() => {

    const fetchData = async () => {
        
      setIsLoading(true);
      
      try {
        
        const data = await getEmails({props, auth});
        setEmails(data.results);
        setError(null);

      } catch (error) {

        setError(error);
        setEmails(null);

      }

      setIsLoading(false);

    };


    fetchData();
      
  }, [props, auth]);

  


  return (

    <>
          
      <table className="table">
                      
        <thead>
            <tr>
                <td>#</td> 
                <td>Remitente / Lista  de destintarios</td> 
                <td>Campaña / Nombre / Asunto</td> 
                <td>Cuerpo</td> 
                <td nowrap="nowrap">Fecha Envío</td> 
                <td>Enviados</td> 
                <td>Pendientes</td> 
                <td>Rebotados</td> 
                <td>Desuscriptos</td> 
                <td>Aperturas</td> 
                <td>Aperturas Únicas</td> 
                <td>Clicks</td> 
                <td>Clicks Únicos</td> 
            </tr>
        </thead>
        <tbody>
            {isLoading ? (
              
              <tr><td colSpan="14" className="c loading">Cargando...</td></tr>

            ) : !emails?.length ? (

              <tr><td colSpan="14" className="c loading">No se encontraron resultados</td></tr>

            ) : emails.map( item => (

              <tr key={item.id}>
                  
                  <td className="c light">
                    <Link className="vinculo" to={`/emails/edit/${item.id}`}>{item.id}</Link>
                  </td>
                  <td>
                    {item.sender_name}<br />
                    <span className="light">{item.sender_email}</span><br />
                    <span className="light">{item.contact_list_name}</span>
                  </td>
                  <td>
                    <span className="light">
                      {item.campaign_year} - {item.campaign_name}<br/>
                      {item.name}
                    </span>
                    <Link className="vinculo block" to={`/emails/${item.id}`}>{item.subject}</Link>
                    
                  </td>
                  <td className="c"><FaCode /></td>
                  <td className="c">{day(item.created_at)}</td>
                  
                  <td className="c"><span className="valor">{item.sent}</span></td>
                  <td className="c"><span className="valor">{item.pending}</span></td>
                  
                  <td className="c">
                    <span className="valor">{item.bounced}</span><br />
                    <span className="light perc">{perc(item.sent,item.bounced)}</span>
                  </td>
                  
                  <td className="c">
                    <span className="valor baja">{item.unsubscribed}</span><br />
                    <span className="light perc">{perc(item.sent,item.unsubscribed)}</span>
                  </td>

                  <td className="c">
                    <span className="valor">{item.opened}</span><br />
                    <span className="light perc">{perc(item.sent,item.opened)}</span>
                  </td>

                  <td className="c">
                    <span className="valor">{item.opened}</span><br />
                    <span className="light perc">{perc(item.sent,item.opened)}</span>
                  </td>


                  <td className="c">
                    <span className="valor">{item.opened}</span><br />
                    <span className="light perc">{perc(item.sent,item.opened)}</span>
                  </td>


                  <td className="c">
                    <span className="valor">{item.opened}</span><br />
                    <span className="light perc">{perc(item.sent,item.opened)}</span>
                  </td>
                  
              </tr>
            ))}
            
        </tbody>

      </table>
    </>
    
    )
}

export default EmailsTable